import extractDomainName from "@serie3/bewerbungshelferleinapi/Modules/Profile/Domain/ExtractUrl";

import { useEffect, useState } from "react";
const SocialButton = ({ socialMedia }) => {
  const link = socialMedia.value;
  const domainname = extractDomainName(link);
  const [icon, setIcon] = useState({
    iconType: "psi-like-2",
    color: "blue",
  });

  useEffect(() => {
    switch (domainname) {
      case "instagram":
        setIcon({
          iconType: "psi-instagram",
          color: "lightred",
        });
        break;
      case "skype":
        setIcon({
          iconType: "psi-skype",
          color: "blue",
        });
        break;
      case "facebook":
        setIcon({
          iconType: "psi-facebook",
          color: "blue",
        });
        break;
      case "pinterest":
        setIcon({
          iconType: "psi-pinterest",
          color: "orange",
        });
        break;
      case "facebook":
        setIcon({
          iconType: "psi-facebook",
          color: "darkblue",
        });
        break;
      case "linkedin":
        setIcon({
          iconType: "psi-linkedin",
          color: "darkblue",
        });
        break;
      case "xing":
        setIcon({
          iconType: "psi-xing",
          color: "lightgreen",
        });
        break;
      case "yahoo":
        setIcon({
          iconType: "psi-yahoo",
          color: "purple",
        });
        break;
      case "twitter":
        setIcon({
          iconType: "psi-twitter",
          color: "black",
        });
        break;
      case "wordpress":
        setIcon({
          iconType: "psi-wordpress",
          color: "black",
        });
        break;
      default:
        setIcon({
          iconType: "psi-rss",
          color: "orange",
        });
    }
  }, [socialMedia]);

  return (
    <a
      href={link}
      target="_blank"
      className={`btn btn-icon btn-hover text-white`}
      style={{backgroundColor: icon.color}}
    >
      <i className={`fs-4 ${icon.iconType}`}></i>
    </a>
  );
};

export default SocialButton;
