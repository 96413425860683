const NavSection = ({ title, children }) => {
  return (
    <div className="mainnav__profile mt-3 d-flex3">
      <h6 className="mainnav__caption mt-0 px-3 fw-bold">{title}</h6>
      <ul className="mainnav__menu nav flex-column">
        {children}
      </ul>
    </div>
  );
};

export default NavSection;
