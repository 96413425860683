import PayModal from "../Modals/PayModal";

const NewButtonWithIcon = ({
  title = "new",
  showPayModal,
  setShowPayModal,
  handleClick,
  color="success"
}) => {
  return (
    <>
      <PayModal show={showPayModal} setShow={setShowPayModal} />
      <button
        type="button"
        className={`btn btn-${color} hstack gap-2`}
        onClick={(e) => {
          handleClick(e);
        }}
      >
        <i className="psi-add fs-4"></i>
        <span className="vr"></span>
        {title}
      </button>
    </>
  );
};

export default NewButtonWithIcon;
