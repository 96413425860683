import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import Timeline from "../../../../Shared/Timeline/Timeline";
import WorkTimelineItem from "./Work/WorkTimelineItem";
import listHandler from "@serie3/common/Domain/listHandler";
import { ProfileConfig } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Resources/Endpoints";
import crudApi from "@serie3/common/api/crudApi";
import WorkexperienceFormView from "./Work/WorkexperienceFormView";
import AddButton from "../../../../Shared/Buttons/AddButton";
import { Row, Col } from "react-bootstrap";
import FormSaveHeader from "./FormSaveHeader";

const ProfileWorkForm = ({ profile }) => {
  const { t } = useTranslation();
  const [showEdit, setShowEdit] = useState(false);
  const [workexperience, setWorkexperience] = useState([]);
  const [editWorkexperience, setEditWorkexperience] = useState();
  const [jobTasks, setJobTasks] = useState([]);

  const editAction = (workexperience) => {
    setEditWorkexperience(workexperience);
    setShowEdit(true);
  };

  const deleteAction = (index, educationId) => {
    const deleteUrl = ProfileConfig.endpoints.workexperience.delete;
    listHandler.deleteFromList(
      index,
      crudApi.del(deleteUrl, educationId),
      setWorkexperience,
      workexperience
    );
  };

  useEffect(() => {
    setWorkexperience(profile.workexperience);
  }, [profile]);

  const AddBtn = () => {
    return (
      <AddButton
        addFunction={() => {
          setEditWorkexperience(null);
          setShowEdit(true);
        }}
      />
    );
  };
  return (
    <>
      <WorkexperienceFormView
        showEdit={showEdit}
        setShowEdit={setShowEdit}
        profileId={profile.id}
        dataList={workexperience}
        setDataList={setWorkexperience}
        editData={editWorkexperience}
        setEditData={setEditWorkexperience}
        jobTasks={jobTasks}
        setJobTasks={setJobTasks}
      />
      <FormSaveHeader
        title={t("Backend.Profiles.Edit.Tabs.WorkExperience.Title")}
        ExtraButton={AddBtn}
      />
      <Row className="mb-3">
        <Col>
          <Timeline>
            {workexperience
              ? workexperience.map((workexperience, index) => {
                  const labelText =
                    workexperience.start + " - " + workexperience.ende;
                  return (
                    <WorkTimelineItem
                      key={index}
                      workexperience={workexperience}
                      editAction={() => {
                        editAction(workexperience);
                      }}
                      deleteAction={() => {
                        deleteAction(index, workexperience.id);
                      }}
                    />
                  );
                })
              : null}
          </Timeline>
        </Col>
      </Row>
    </>
  );
};

export default ProfileWorkForm;
