import JobTask from "../Entities/JobTask";
import * as Yup from "yup";
import CrudApi from "@serie3/common/api/crudApi";
import { ProfileConfig } from "../Resources/Endpoints";
import listHandler from "@serie3/common/Domain/listHandler";

const createJobTaskForm = (
  dataList,
  setDataFunction,
  showErrorFunction
) => {
  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    start: Yup.string().required("Required")
  });

  const onSubmit = async (values, actions) => {
    if (values.id > 0) {
      let payload = JSON.stringify(values, null, 2);
      const response = await CrudApi.edit(
        ProfileConfig.endpoints.workexperience.editJobTask,
        payload
      );
      listHandler.updateList(
        response,
        payload,
        dataList,
        setDataFunction,
        showErrorFunction
      );
    } else {
      let payload = JSON.stringify(values, null, 2);
      console.log(payload);
      const response = await CrudApi.createNew(
        ProfileConfig.endpoints.workexperience.addJobTask,
        payload
      );
      listHandler.newToList(
        response,
        dataList,
        setDataFunction,
        showErrorFunction
      );
    }
  };

  return {
    initialValues: JobTask,
    onSubmit: (values, actions) => {
      onSubmit(values, actions);
    },
  };
};

const setEditData = (editData) => {
  const date = new Date();
  let Profile = {
    id: editData.id,
    description: editData.description,
    workexperience: editData.workexperience,    
  };

  return Profile;
};

export default { createJobTaskForm, setEditData };
