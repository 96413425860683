import "@serie3/common/Assets/css/dropzone.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Notloggedin from "./components/Public/Notloggedin";
import useToken from "./security/useToken";
import AuthContext from "@serie3/common/auth/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import BackendRouter from "./components/LoggedIn/BackendRouter";
import Blogpage from "./components/Public/Blog/Blogpage";

function App() {
  const { token, setToken } = useToken();

  useEffect(() => {
    if (window.location.hash === "#pricing") {
      const element = document.getElementById("pricing");
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [window.location.pathname]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <AuthContext.Provider value={{ token, setToken }}>
        <Router>
          <Routes>
            {!token && (
              <Route path="/*" element={<Notloggedin setToken={setToken} token={token} />} />
            )}

            <Route path="/blog/:splat" element={<Blogpage />} />
            <Route path="/*" element={<BackendRouter />} />
          </Routes>
        </Router>
      </AuthContext.Provider>
    </>
  );
}

export default App;
