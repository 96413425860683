import { PaymentConfig } from "../Resources/Endpoints";
import PaymentData from "../Entities/PaymentData";
import crudApi from "../../../api/crudApi";
import PaymentDataForm from "../Forms/PaymentDataForm";

/** returns true if there is payment data available */
const loadPaymentData = async (setIsLoaded, formik) => {
  const response = await crudApi.justGet(
    PaymentConfig.endpoints.paymentData.get
  );
  if (response.ok && Object.keys(response.data).length > 0) {
    formik.setValues(PaymentDataForm.setEditData(response.data));
    setIsLoaded(true);
    return true;
  } else {
    PaymentDataForm.setEditData(PaymentData);
  }
  setIsLoaded(true);
  return false;
};

export default loadPaymentData;
