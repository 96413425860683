import crudApi from "@serie3/common/api/crudApi";
import { ApplicationConfig } from "../Resources/Endpoints";

const updateStatus = async (
  application,
  status,
  setApplicationStatus,
  updateView,
  setUpdateView
) => {
  setUpdateView(!updateView);
  application["status"] = status;
  
  //Date is set in the backend and not via frontend
  delete application.date;

  //once the profile is set it can not be deleted anymore
  if (application.hasOwnProperty("profile")) {
    delete application.profile;
  }
  const payload = JSON.stringify(application);

  const response = await crudApi.edit(
    ApplicationConfig.endpoints.edit,
    payload
  );
  if (response.status === 200) {
    switch (status) {
      case "todo":
        setApplicationStatus("Todo");
        break;
      case "applied":
        setApplicationStatus("Applied");
        break;
      case "waiting":
        setApplicationStatus("waiting");
        break;
      case "canceled":
        setApplicationStatus("Canceled");
        break;
      case "appointment":
        setApplicationStatus("Appointment");
        break;
      case "rejected":
        setApplicationStatus("Rejected");
        break;
      case "accepted":
        setApplicationStatus("Accepted");
        break;
    }
  }
};

export default updateStatus;
