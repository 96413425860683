const TimelineItem = ({ active = false, children, start, end }) => {
  return (
    <div className={`tl-entry ${active ? "active" : ""}`}>
      <div className="tl-time">
        <div className="tl-date">{start}</div>
        <div className="tl-time">- {end}</div>
      </div>
      <div className="tl-point"></div>
      <div className="tl-content card" style={{ width: "100%" }}>
        <div className="card-body">
          <figure className="m-0">{children}</figure>
        </div>
      </div>
    </div>
  );
};

export default TimelineItem;
