import NewModal from "../../Modals/NewModal";
import SaveButton from "../../Buttons/SaveButton";
import TextField from "../TextField";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SelectField from "../SelectField";

const LanguageForm = ({
  showEdit,
  setShowEdit,
  dataList,
  editLanguage,
  onShow = null,
  formik,
  setDataList,
}) => {
  const { t } = useTranslation();

  let itemIndex = -1;
  if (editLanguage) {
    itemIndex = dataList.findIndex((obj) => obj.id === editLanguage.id);
  } else {
    itemIndex = dataList.length - 1;
  }

  const saveNew = (e) => {
    formik.handleSubmit(e);
    setShowEdit(false);
    e.preventDefault();
  };

  const saveEdit = (e) => {
    const language = formik.values.languages[itemIndex];
    dataList.splice(itemIndex, 1, language);
    let tmpList = [...dataList];
    setShowEdit(false);
    setDataList(tmpList);
    formik.handleSubmit(e);
  };

  const Save = () => {
    return <SaveButton submitFunction={editLanguage ? saveEdit : saveNew} />;
  };

  return itemIndex >= 0 ? (
    <NewModal
      title={t("Backend.Profiles.Edit.Tabs.Titles.LanguageModalTitle")}
      show={showEdit}
      setShow={setShowEdit}
      ExtraButton={Save}
    >
      <Row>
        <Col>
          <TextField
            label={t("Backend.Profiles.Edit.Tabs.Titles.LanguageModal.Name")}
            valueName={"languages[" + itemIndex + "].name"}
            formik={formik}
            value={formik.values.languages[itemIndex].name}
          />
        </Col>
        <Col>
          <SelectField
            formik={formik}
            label={t("Backend.Profiles.Edit.Tabs.Titles.LanguageModal.Level")}
            valueName={"languages[" + itemIndex + "].level"}
            value={formik.values.languages[itemIndex].level}
          >
            <option></option>
            <option value="100">{t("Backend.Profiles.Edit.Tabs.Titles.LanguageModal.Choices.MotherTongue")}</option>
            <option value="75">{t("Backend.Profiles.Edit.Tabs.Titles.LanguageModal.Choices.Advanced")}</option>
            <option value="50">{t("Backend.Profiles.Edit.Tabs.Titles.LanguageModal.Choices.Intermediate")}</option>
            <option value="25">{t("Backend.Profiles.Edit.Tabs.Titles.LanguageModal.Choices.Beginner")}</option>
          </SelectField>
        </Col>
      </Row>
    </NewModal>
  ) : null;
};

export default LanguageForm;
