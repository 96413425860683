import TimelineItem from "../../../../../Shared/Timeline/TimelineItem";
import { useTranslation } from "react-i18next";
import CompleteDropdown from "../../../../../Shared/Dropdown/CompleteDropdown";
import { Row, Col } from "react-bootstrap";

const WorkTimelineItem = ({ workexperience, editAction, deleteAction }) => {
  const { t } = useTranslation();
  return (
    <TimelineItem start={workexperience.start} end={workexperience.ende}>
      <h5 className="card-title">
        <Row className="mb-2">
          <Col>
            {workexperience.position_name}&nbsp;|&nbsp;
            <small>@{workexperience.company}</small>
            <CompleteDropdown
              deleteAction={deleteAction}
              editAction={editAction}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="auto">{workexperience.position_type}</Col>
        </Row>
      </h5>
      <p className="card-text">
        <Row>
          <Col className="mb-3">{workexperience.description}</Col>
        </Row>
        <Row>
          <Col>
            <ul className="list-group">
              {workexperience.job_tasks
                ? workexperience.job_tasks.map((task, index) => (
                    <li className="list-group-item" key={index}>
                      {task.description}
                    </li>
                  ))
                : null}
            </ul>
          </Col>
        </Row>
      </p>
    </TimelineItem>
  );
};

export default WorkTimelineItem;
