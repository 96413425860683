import * as Yup from "yup";
import PaymentData from "../Entities/PaymentData";
import crudApi from "@serie3/common/api/crudApi";
import { PaymentConfig } from "../Resources/Endpoints";

const createPaymentDataForm = () => {
  const onSubmit = async (values) => {
    console.log(values);
    if (values.id > 0) {
      let payload = JSON.stringify(values, null, 2);
      const response = await crudApi.edit(
        PaymentConfig.endpoints.paymentData.edit,
        payload
      );
    } else {
      let payload = JSON.stringify(values, null, 2);
      const response = await crudApi.createNew(
        PaymentConfig.endpoints.paymentData.new,
        payload
      );
    }
  };

  return {
    initialValues: PaymentData,
    onSubmit: (values, actions) => {
      onSubmit(values);
    },
  };
};

const setEditData = (editData) => {
  let PaymentData = {
    id: editData.id,
    first_name: editData.first_name,
    last_name: editData.last_name,
    company_name: editData.company_name,
    street: editData.street,
    house_number: editData.house_number,
    postal_code: editData.postal_code,
    city: editData.city,
    country: editData.country,
    vat_number: editData.vat_number,
    delivery_street: editData.delivery_street,
    delivery_house_number: editData.delivery_house_number,
    delivery_postal_code: editData.delivery_postal_code,
    delivery_city: editData.delivery_city,
    delivery_country: editData.delivery_country,
  };

  return PaymentData;
};

export default { createPaymentDataForm, setEditData };
