import NewModal from "./NewModal";
import { useEffect, useState } from "react";
import crudApi from "@serie3/common/api/crudApi";
import { ProfileConfig } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Resources/Endpoints";
import LoadingModal from "./LoadingModal";
import downloadHandler from "@serie3/common/Shared/downloadHandler";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";


const PreviewModal = ({ show, setShow, id, setId, title, downloadUrl }) => {
  const [decodedHtml, setDecodedHtml] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const { t } = useTranslation();
  const DownloadButton = () => {
    return (
      <Button
        onClick={() => {
          setShowLoading(true);
          downloadHandler(downloadUrl, title + ".pdf", null, setShowLoading);
        }}
      >
        {t("Backend.Dropdown.Download")}
      </Button>
    );
  };

  useEffect(() => {
    const loadHTML = async () => {
      if (id > 0) {
        const response = await crudApi.justGet(
          ProfileConfig.endpoints.htmlPreview + id
        );
        if (response.status === 200) {
          let html = response.data;
          html = html.replace(/\\u([\d\w]{4})/gi, (match, grp) =>
            String.fromCharCode(parseInt(grp, 16))
          );
          html = html.replace(/\\n/g, ""); // Remove newline escape characters if not needed
          setDecodedHtml(html);
        }
      }
    };

    loadHTML();
  }, [id]);

  return decodedHtml === "" ? null : (
    <>
      <LoadingModal showSpinner={showLoading} setShowSpinner={setShowLoading} />
      <NewModal
        show={show}
        setShow={setShow}
        fullscreen={true}
        onExit={() => {
          setDecodedHtml("");
          setId(-1);
        }}
        noHeader={true}
        noPadding={true}
        ExtraButton={DownloadButton}
      >
        <iframe
          srcDoc={decodedHtml}
          title="Preview"
          style={{ width: "100%", height: "100%" }}
        />
      </NewModal>
    </>
  );
};

export default PreviewModal;
