import { Row, Col } from "react-bootstrap";
import TextField from "../../../../Shared/Form//TextField";
import Checkbox from "../../../../Shared/Form/Checkbox";
import DateField from "../../../../Shared/Form/DateField";
import NumberField from "../../../../Shared/Form/NumberField";
import FormSaveHeader from "./FormSaveHeader";
import { useTranslation } from "react-i18next";

const PricesAndAvailability = ({ formik }) => {
  const { t } = useTranslation();
  return (
    <>
      <Row className="g-3 mt-3">
        <Col md={12}>
          <FormSaveHeader
            formik={formik}
            title={t("Backend.Profiles.Edit.Tabs.Titles.Dates")}
          />
        </Col>
      </Row>
      <Row className="g-3">
        <Col>
          <DateField
            formik={formik}
            label={"Available from"}
            valueName={"available_from"}
          />
        </Col>
        <Col>
          <DateField
            formik={formik}
            label={"Available to"}
            valueName={"available_to"}
          />
        </Col>
      </Row>
      <Row className="g-3 mt-3">
        <Col md={12}>
          <FormSaveHeader
            formik={formik}
            title={t("Backend.Profiles.Edit.Tabs.Titles.Rates")}
          />
        </Col>
      </Row>
      <Row className="g-3">
        <Col>
          <NumberField
            formik={formik}
            label={"Max Weekly hours"}
            valueName={"max_hours"}
            formClass={"mb-3"}
          />
          <Checkbox
            formik={formik}
            label={"Fulltime only"}
            valueName={"fulltime_only"}
          />
        </Col>
      </Row>
      <Row className="g-3 mt-3">
        <Col>
          <TextField
            formik={formik}
            label={"Hourly rate on site"}
            valueName={"hourly_rate_on_site"}
            formClass={"mb-3"}
          />
          <Checkbox
            formik={formik}
            label={"Publish on site rate"}
            valueName={"hourly_rate_on_site_public"}
          />
        </Col>
        <Col>
          <TextField
            formik={formik}
            label={"Hourly rate remote"}
            valueName={"hourly_rate_remote"}
            formClass={"mb-3"}
          />
          <Checkbox
            formik={formik}
            label={"Publish remote rate"}
            valueName={"hourly_rate_remote_public"}
          />
        </Col>
      </Row>
      <Row className="g-3 mt-3">
        <Col>
          <TextField
            formik={formik}
            label={"Daily rate on site"}
            valueName={"daily_rate_on_site"}
            formClass={"mb-3"}
          />
          <Checkbox
            formik={formik}
            label={"Publish daily on site rate"}
            valueName={"daily_rate_on_site_public"}
          />
        </Col>
        <Col>
          <TextField
            formik={formik}
            label={"Daily remote rate"}
            valueName={"daily_rate_remote"}
            formClass={"mb-3"}
          />
          <Checkbox
            formik={formik}
            label={"Publish daily remote rate"}
            valueName={"daily_rate_remote_public"}
          />
        </Col>
      </Row>
    </>
  );
};

export default PricesAndAvailability;
