const Mailitem = () => {
  return (
    <div className="list-group-item list-group-item-action d-flex align-items-center">
      <div className="d-flex flex-column flex-md-row flex-shrink-0 gap-3 align-items-center">
        <a href="#" className="text-decoration-none text-light">
          <i className="psi-star fs-5"></i>
        </a>
      </div>

      <div className="flex-fill min-w-0 ms-3">
        <div className="d-flex flex-wrap flex-xl-nowrap align-items-xl-center">
          <div className="w-md-160px flex-shrink-0">
            <a href="#" className="text-dark fw-bold m-0 text-decoration-none">
              Michael Robert
            </a>
          </div>

          <div className="flex-shrink-0 ms-auto order-xl-3">
            <span className="me-2">
              <i className="psi-paperclip"></i>
            </span>
            <small className="text-body-secondary">05:55 PM</small>
          </div>

          <div className="flex-fill w-100 min-w-0 mt-2 mt-xl-0 mx-xl-3 order-xl-2">
            <a
              href="#"
              className="d-block text-dark fw-bold m-0 text-decoration-none text-truncate"
            >
              This is an example if there is a really really long text. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
              minim veniam.
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mailitem;
