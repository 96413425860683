import i18n from "../../../../web/src/i18n";

const setLanguageLevelText = (percentage) => {
  switch (percentage) {
    case 100:
      return i18n.t("Backend.Profiles.Edit.Tabs.Titles.LanguageModal.Choices.MotherTongue");

    case 75:
      return i18n.t("Backend.Profiles.Edit.Tabs.Titles.LanguageModal.Choices.Advanced");

    case 50:
      return i18n.t("Backend.Profiles.Edit.Tabs.Titles.LanguageModal.Choices.Intermediate");

    case 25:
      return i18n.t("Backend.Profiles.Edit.Tabs.Titles.LanguageModal.Choices.Beginner");
      
    default:
      return "-";
  }
};

export default setLanguageLevelText;
