const handleSkillSplitup = (allSkills) => {
  const skillarray = new Array();
  let currentCategory = "no category";

  if (allSkills.length > 0) {
    const sorted = allSkills.map((skill, index) => {
      if (skill.category !== currentCategory) {
        currentCategory = skill.category;
        if (typeof skillarray[currentCategory] === "undefined") {
          skillarray[currentCategory] = new Array();
        }
        skillarray[currentCategory].push(skill);
        return skillarray;
      } else {
        skillarray[currentCategory].push(skill);
        return skillarray;
      }
    });
    return skillarray;
  }
  return null;
};

export default handleSkillSplitup;
