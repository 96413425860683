import NewModal from "../../../../../Shared/Modals/NewModal";
import { Col, Row } from "react-bootstrap";
import TextField from "../../../../../Shared/Form/TextField";
import TextArea from "../../../../../Shared/Form/TextArea";
import EducationForm from "@serie3/bewerbungshelferleinapi/Modules/Profile/Forms/EducationForm";
import { useFormik } from "formik";
import Education from "@serie3/bewerbungshelferleinapi/Modules/Profile/Entities/Education";
import DateField from "../../../../../Shared/Form/DateField";
import SaveButton from "../../../../../Shared/Buttons/SaveButton";
import { useTranslation } from "react-i18next";

const EducationFormView = ({
  showEdit,
  setShowEdit,
  editEducation,
  profileId,
  dataList,
  setDataList,
  showErrorFunction = null,
}) => {
  const editEducationForm = EducationForm.createEducationForm(
    dataList,
    setDataList,
    showErrorFunction,
    setShowEdit
  );
  const formik = useFormik(editEducationForm);
  const { t } = useTranslation();

  const Save = () => {
    return (
      <SaveButton
        submitFunction={(e) => {
          formik.handleSubmit(e);
          e.preventDefault();
        }}
      />
    );
  };

  return (
    <>
      <NewModal
        show={showEdit}
        setShow={setShowEdit}
        ExtraButton={Save}
        title={t("Backend.Profiles.Edit.Tabs.Education.Form.Title")}
        onShow={() => {
          editEducation
            ? formik.setValues(EducationForm.setEditData(editEducation))
            : formik.setValues(Education);

          formik.setFieldValue("profile.id", profileId);
        }}
        onExit={() => {
          formik.resetForm({ values: { Education } });
        }}
      >
        <Row>
          <Col>
            <TextField
              formik={formik}
              label={t("Backend.Profiles.Edit.Tabs.Education.Form.Name")}
              valueName={"name"}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              formik={formik}
              label={t("Backend.Profiles.Edit.Tabs.Education.Form.Institution")}
              valueName={"subject_area"}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <DateField
              formik={formik}
              label={t("Backend.Profiles.Edit.Tabs.Education.Form.Start")}
              valueName={"start"}
            />
          </Col>
          <Col>
            <DateField
              formik={formik}
              label={t("Backend.Profiles.Edit.Tabs.Education.Form.End")}
              valueName={"ende"}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mt-3">
            <TextArea
              formik={formik}
              label={t("Backend.Profiles.Edit.Tabs.Education.Form.Description")}
              valueName={"description"}
            />
          </Col>
        </Row>
      </NewModal>
    </>
  );
};

export default EducationFormView;
