import { Form } from "react-bootstrap";

const DateField = ({ formik, formClass = null, label = null, valueName, style={} }) => {
  return (
    <Form.Group
      className={formClass ? formClass : "form-inline"}
      controlId={valueName}
      style={style}
    >
       {label ? <Form.Label>{label}</Form.Label> : null}
      <Form.Control
        type="date"
        name={valueName}
        placeholder={label}
        value={formik.values[valueName]}
        isInvalid={!!formik.errors[valueName]}
        onChange={formik.handleChange}
        style={{ width: "100%" }}
      />
    </Form.Group>
  );
};

export default DateField;
