import React from "react";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      style={{borderRadius: "0", marginLeft: "-4px"}}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));

  export default CustomToggle;
