const ColorCard = ({
  color = "success",
  title = null,
  text = null,
  icon = "psi-data-storage",
}) => {
  const cardClass = "card text-white overflow-hidden mb-3 bg-" + color;
  const cardIcon = "text-reset text-opacity-75 fs-3 me-2 " + icon;

  return (
    <div className={cardClass}>
      <div className="p-3 pb-2">
        <h5 className="mb-3">
          <i className={cardIcon}></i>
          {title}
        </h5>
        <div className="p-3 text-center">
          <span className="display-5">{text}</span>
        </div>
      </div>
    </div>
  );
};

export default ColorCard;
