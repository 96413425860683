import crudApi from "@serie3/common/api/crudApi";
import { ProfileConfig } from "../Resources/Endpoints";

const loadData = async (id, setEditProfile, setOriginalSkillList, setLanguages, setSearchingFor, setSocialMediaList, formik, setIsLoaded, ProfileForm, setProfileType) => {
    const profile = await crudApi.show(ProfileConfig.endpoints.show, id);
    setEditProfile(profile);
    setOriginalSkillList(profile.skills);
    setLanguages([...profile.languages]);
    setSearchingFor([...profile._searching]);
    setSocialMediaList([...profile.socialmedias]);
    setProfileType(profile.freelance);
    formik.setValues(ProfileForm.setEditData(profile));
    setIsLoaded(true);
  };

export default loadData;