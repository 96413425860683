import crudApi from "../api/crudApi";
import UserConfig from "@serie3/bewerbungshelferleinapi/Modules/User/Resources/Endpoints";

async function fetchSettings(setSettings) {
    const response = await crudApi.getAll(UserConfig.endpoints.settings.get);
    if (response.status === 200) {
      if (
        typeof response.data.Settings !== "undefined" &&
        response.data.Settings.length > 0
      ) {
        setSettings(response.data.Settings[0]);
      }
    }
  }

  export default fetchSettings;