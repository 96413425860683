import ProfileNav from "../Shared/Navigation/Main/ProfileNav";
import NavSection from "../Shared/Navigation/Main/NavSection";
import { useTranslation } from "react-i18next";
import NavLink from "../Shared/Navigation/Main/NavLink";
import useToken from "../../security/useToken";
import firebaseConfig from "@serie3/bewerbungshelferleinapi/Config/Firebase/firebase";
import { getAuth, signOut } from "firebase/auth";
import { initializeApp } from "firebase/app";
import Timer from "./Timer";

const Nav = () => {
  const { t } = useTranslation();
  const { token, setToken } = useToken();
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  return (
    <nav id="mainnav-container" className="mainnav">
      <div className="mainnav__inner">
        <div className="mainnav__top-content scrollable-content pb-5">
          <ProfileNav />
          <NavSection title={t("Backend.Navigation.Navigation")}>
            <NavLink
              title={t("Backend.Navigation.Dashboard")}
              target="/"
              icon="pli-home fs-5 me-2"
            />
            <NavLink
              title={t("Backend.Navigation.Profile")}
              target="/profile"
              icon="pli-business-man-woman fs-5 me-2"
            />
            <NavLink
              title={t("Backend.Navigation.Applications")}
              target="/applications"
              icon="pli-file-pictures fs-5 me-2"
            />
            {/*
            <NavLink
              title={t("Backend.Navigation.Mail")}
              target="/jobmails"
              icon="pli-mail fs-5 me-2"
            />*/}
          </NavSection>
        </div>

        <div className="mainnav__bottom-content border-top pb-2">
          <ul id="mainnav" className="mainnav__menu nav flex-column">
            <li className="nav-item">
              <div className="mininav-toggle">
                <i className="pli-unlock fs-5 me-2"></i>
                <a
                  href="#"
                  className="nav-link mininav-content ms-1"
                  onClick={() => {
                    setToken(null, null);
                    window.location.reload();
                    signOut(auth);
                  }}
                >
                  {t("Backend.Navigation.Logout")}
                </a>
                <a
                  href="#"
                  className="nav-link mininav-content ms-4"
                  style={{ fontSize: "smaller" }}
                  onClick={() => {}}
                >
                  <Timer small={true} />
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
