/** Calculates how many applications are in which state */
const calculateDashboardNumbers = (applications = []) => {
    const numbers = {
        todo: 0,
        running: 0,
        done: 0
    }
    applications.length > 0 && applications.forEach(application => {
        switch(application.status.toLowerCase()){
            case "todo": 
                numbers.todo++;
                break;
            case "accepted":
            case "rejected":
            case "canceled":
                numbers.done++;
                break;
            default:
                numbers.running++;
        }
        
    });
    
    return numbers;
};

export default calculateDashboardNumbers;
