import { useTranslation } from "react-i18next";
import SocialButton from "../../../Shared/Buttons/SocialButton";
import { ProfileConfig } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Resources/Endpoints";
import { useState } from "react";
import downloadHandler from "@serie3/common/Shared/downloadHandler";
import PDFModal from "../../../Shared/Modals/PDFModal";
import FileDropzone from "../../../Shared/Modals/FileDropzone";
import NewModal from "../../../Shared/Modals/NewModal";
import ProfilePicture from "./ProfilePicture";
import PreviewModal from "../../../Shared/Modals/PreviewModal";

const ProfileHeader = ({ formik, socialMediaList, profile }) => {
  const { t } = useTranslation();
  const [showSpinner, setShowSpinner] = useState(false);
  const [showProfileUpload, setShowProfileUpload] = useState(false);
  const [triggerRerenderProfile, setTriggerRerenderProfile] = useState(-1);
  const [showPreview, setShowPreview] = useState(false);
  const [previewId, setPreviewId] = useState(-1);

  const setData = () => {
    setTriggerRerenderProfile(Math.random());
  };

  const downloadUrl = ProfileConfig.endpoints.download + profile.id;
  const uploadUrl = ProfileConfig.endpoints.edit;

  const downloadName = (profile.vorname + "_" + profile.name + "_" +profile.profiletitle).replace(/ /g,"_");
  return (
    <>
      <PreviewModal
        show={showPreview}
        setShow={setShowPreview}
        id={previewId}
        setId={setPreviewId}
        title={downloadName}
        downloadUrl={ProfileConfig.endpoints.download + profile.id}
      />

      <PDFModal showSpinner={showSpinner} setShowSpinner={setShowSpinner} />
      <NewModal show={showProfileUpload} setShow={setShowProfileUpload}>
        <FileDropzone
          uploadUrl={uploadUrl}
          additionalData={{ id: profile.id }}
          edit={true}
          setName={false}
          setData={setData}
        />
      </NewModal>
      <div
        className="content__wrap d-md-flex align-items-start"
      >
        <figure className="m-0">
          <div className="d-inline-flex align-items-center position-relative pt-xl-5 mb-3">
            <div
              className="flex-shrink-0"
              onClick={() => {
                setShowProfileUpload(true);
              }}
            >
              <ProfilePicture
                profileId={profile.id}
                rerender={triggerRerenderProfile}
              />
            </div>
            <div className="flex-grow-1 ms-4">
              <a href="#" className="h3 btn-link">
                {formik.values["vorname"]} {formik.values["name"]}
              </a>
              <p className="text-muted m-0">{formik.values["profiletitle"]}</p>
              <div className="mt-3 text-reset">
                {socialMediaList.map((socialMedia, index) => {
                  return <SocialButton socialMedia={socialMedia} />;
                })}
              </div>
            </div>
          </div>
          <blockquote className="blockquote">
            <p>{formik.values["mantra"]}</p>
          </blockquote>
        </figure>
        <div className="d-inline-flex justify-content-end pt-xl-5 gap-2 ms-auto">
          {/* <button className="btn btn-light text-nowrap">
            {t("Backend.Buttons.ShareProfile")}
              </button> */}
          <button
            className="btn btn-success text-nowrap"
            onClick={() => {
              setShowPreview(true);
              setPreviewId(profile.id);
            }}
          >
            <i className="pli-eye-visible fs-3 me-2" />
            {t("Backend.Dropdown.Preview")}
          </button>
          <button
            className="btn btn-success text-nowrap"
            onClick={() => {
              setShowSpinner(true);
              downloadHandler(
                downloadUrl,
                downloadName + ".pdf",
                null,
                setShowSpinner
              );
            }}
          >
            <i className="pli-file-download fs-3 me-2" />
            {t("Backend.Buttons.DownloadProfile")}
          </button>
        </div>
      </div>
    </>
  );
};

export default ProfileHeader;
