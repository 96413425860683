const UserConfig = {
  endpoints: {
    deleteAccount: "/account/requestdelete",
    confirmDeleteAccount: "/account/confirmdelete",
    cancelDelete: "/account/canceldelete",
    confirmCancelDelete: "/account/confirmcanceldelete",
    checkDeletion: "/account/goingtobedeleted",
    settings: {
      edit: "/usersettings/edit",
      get: "/usersettings/show",
      isPayedUser: "/usersettings/ispayeduser"
    },
    social: {
      gmail: {
        getAuthUrl: "/connections/gmail/getauthurl",
        connect: "/connections/gmail/connect",
        isConneted: "/connections/gmail/isconnected",
        refreshConnection: "/connections/gmail/refreshconnection",
        disconnect: "/connections/gmail/disconnect"
      }
    }
  }
};

export default UserConfig;
