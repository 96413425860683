import { PaymentConfig } from "../Resources/Endpoints";
import crudApi from "../../../api/crudApi";

/** returns true if there is payment data available */
const loadSubscriptionData = async (setPaymentData) => {
    const response = await crudApi.justGet(
      PaymentConfig.endpoints.subscriptions.list
    );
    if (response.ok && Object.keys(response.data).length > 0) {
        console.log(response.data);
        setPaymentData(response.data[PaymentConfig.dataFields.subscriptions]);
    }
    return false;
  };
  
  export default loadSubscriptionData;
  