import { Link } from "react-router-dom";
import { Dropdown, Button } from "react-bootstrap";
import i18n from "../../../i18n";
import CustomToggle from "../../Shared/Dropdown/CustomToggle";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import LoginModal from "./LoginModal";

const NotLoggedInHeader = ({ setToken, token }) => {
  const [showLogin, setShowLogin] = useState(false);
  const { t } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <>
      <LoginModal setToken={setToken} setShow={setShowLogin} show={showLogin} />

      <span style={{ marginRight: "10px" }} onClick={() => {setShowLogin(true)}}>{t("Login.Login")}</span>
      <Link to={"/signup"}>
        <div className="btn-group">
          <Button className="btn btn-success">{t("Signup.Signup")}</Button>
          <Dropdown
            style={{
              float: "right",
              marginLeft: "-5px",
            }}
          >
            <Dropdown.Toggle id="dropdown-basic" as={CustomToggle}>
              <button
                type="button"
                className="btn btn-success dropdown-toggle dropdown-toggle-split"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="visually-hidden">Toggle Dropdown</span>
              </button>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => changeLanguage("de")}>
                <span className="fi fi-de fis" />
                &nbsp; {t("Translation.German")}
              </Dropdown.Item>
              <div className="dropdown-divider" />
              <Dropdown.Item onClick={() => changeLanguage("en")}>
                <span className="fi fi-us fis" />
                &nbsp; {t("Translation.English")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Link>
    </>
  );
};

export default NotLoggedInHeader;
