import { Row, Col, Card } from "react-bootstrap";

const ApplicationCompose = ({ application = null }) => {
  return (
    <Col xs={12} sm={12} md={4}>
      <Card>
        <Card.Body>
          <h5>
            {application.job_title}
            <br />
            <span style={{ fontSize: "smaller", fontWeight: "lighter" }}>
              @{application.hireing_company}
            </span>
          </h5>
          <Row>
            <Col>
              <a className="btn-link" target="_blank" href={application.link}>
                {application.page}
              </a>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default ApplicationCompose;
