import React from "react";
import { Dropdown } from "react-bootstrap";
import EllipsisVerticalToggle from "../../../../../Shared/Dropdown/EllipsisVerticalToggle";
import { faTrash, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import listHandler from "@serie3/common/Domain/listHandler";
import setSkilltext from "@serie3/bewerbungshelferleinapi/Modules/Profile/Domain/setSkillText";
import CompleteDropdown from "../../../../../Shared/Dropdown/CompleteDropdown";

const Skillswrapper = ({
  skillList,
  setEditFunction,
  setShowEdit,
  dataList,
  setDataList,
  formik,
}) => {
  const deleteFromList = (editedItem, e) => {
    const itemIndex = dataList.findIndex((obj) => obj.id === editedItem.id);
    listHandler.deleteFromList(itemIndex, null, setDataList, dataList);
    formik.setFieldValue("skills", dataList);
    formik.handleSubmit(e);
    e.preventDefault();
  };

  return skillList ? (
    <>
      {Object.keys(skillList).map((category, index) => {
        return (
          <React.Fragment key={index}>
            <thead>
              <tr>
                <th colSpan={3}>{category}</th>
              </tr>
            </thead>
            <tbody>
              {skillList[category].map((skill, index) => {
                return (
                  <tr key={skill.id}>
                    <td>{skill.name}</td>
                    <td>{setSkilltext(skill.percentage)}</td>
                    <td>
                      <CompleteDropdown
                        editAction={() => {
                          setEditFunction(skill);
                          setShowEdit(true);
                        }}
                        deleteAction={(e) => {
                          deleteFromList(skill, e);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </React.Fragment>
        );
      })}
    </>
  ) : null;
};

export default Skillswrapper;
