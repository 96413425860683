import Settings from "../Entities/Settings";
import * as Yup from "yup";
import crudApi from "@serie3/common/api/crudApi";
import UserConfig from "../Resources/Endpoints";

const createSettingsForm = (i18n) => {
  const validationSchema = Yup.object({});

  const onSubmit = async (values) => {
    let payload = JSON.stringify(values, null, 2);
    const response = await crudApi.edit(
      UserConfig.endpoints.settings.edit,
      payload
    );
    if (response.status === 200) {
      i18n.changeLanguage(response.data.language);
    }
  };

  return {
    initialValues: Settings,
    onSubmit: (values, actions) => {
      onSubmit(values);
    },
  };
};

const setEditData = (editData) => {
  let Settings = {
    id: editData.id,
    language: editData.language,
  };
};


export default {createSettingsForm, setEditData}