import Card from "../../Shared/Card";
import TextField from "../../Shared/Form/TextField";
import { useTranslation, Trans } from "react-i18next";
import createRegisterForm from "@serie3/common/Form/Register/RegisterForm";
import { useState } from "react";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";

const Signup = () => {
  const { t } = useTranslation();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showProcess, setShowProcess] = useState(false);

  const registerForm = createRegisterForm(
    setShowSuccess,
    setShowError,
    setShowProcess
  );

  const formik = useFormik(registerForm);

  return (
    <Card>
      <div className="text-center">
        <h1 className="h3">
          <Trans>Login.Register.Title</Trans>
        </h1>
        <p>
          <Trans>Login.Register.Subtitle</Trans>
        </p>
      </div>
      <Form className="mt-4" onSubmit={formik.handleSubmit}>
        <div className="mb-3">
          <TextField
            placeholder={t("Login.Username")}
            style={{ width: "270px" }}
            formik={formik}
            valueName={"username"}
          />
        </div>
        <div className="mb-3">
          <TextField
            placeholder={t("Login.Email")}
            style={{ width: "270px" }}
            formik={formik}
            valueName={"email"}
          />
        </div>
        <div className="mb-3">
          <TextField
            placeholder={t("Login.Password")}
            formik={formik}
            valueName={"password"}
            password={true}
          />
        </div>
        <div className="mb-3">
          <TextField
            placeholder={t("Login.PasswordConfirm")}
            formik={formik}
            valueName={"passwordconfirmation"}
            password={true}
          />
        </div>
        <div className="d-grid mt-5">
          <button
            className="btn btn-primary btn-lg"
            type="submit"
            onClick={() => setShowError(false)}
          >
           {t("Login.SignUpButton")}
          </button>
        </div>
      </Form>
      <div className="d-flex justify-content-between mt-4">
        <Link
          to={"/resetpassword"}
          className="btn-link text-decoration-none mr-3"
        >
          {t("Login.ForgotLink")}
        </Link>
        <Link to={"/login"} className="btn-link text-decoration-none">
          {t("Login.Login")}
        </Link>
      </div>
      <div className="border-top pt-3 mt-3 text-center">
        {showError ? (
          <span style={{ color: "red" }}> {t("Login.Messages.Error")} </span>
        ) : (
          ""
        )}
        {showSuccess ? (
          <span style={{ color: "green" }}>{t("Login.Messages.Success")} </span>
        ) : (
          ""
        )}
        {showProcess ? t("Login.Messages.Progress") : ""}
      </div>
    </Card>
  );
};

export default Signup;
