import ContentWrapper from "../../Shared/Basics/ContentWrapper";
import Profile from "./Profile";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ProfileConfig } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Resources/Endpoints";
import { useEffect, useState } from "react";
import crudApi from "@serie3/common/api/crudApi";
import { createNewProfile } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Domain/Buttons";
import { useNavigate } from "react-router-dom";
import NewButtonWithIcon from "../../Shared/Buttons/NewButtonWithIcon";
import PreviewModal from "../../Shared/Modals/PreviewModal";

const Profiles = () => {
  const { t } = useTranslation();
  const [profiles, setProfiles] = useState([]);
  const [showPayModal, setShowPayModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [previewId, setPreviewId] = useState(-1);
  const [profileTitle, setProfileTitle] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const endpoint = ProfileConfig.endpoints.getAll;
    crudApi.getAll(endpoint, setProfiles, "profile");
  }, []);

  return (
    <ContentWrapper
      title={t("Backend.Profiles.Index.Title")}
      welcomeText={t("Backend.Profiles.Index.Subtitle")}
      titleText={t("Backend.Profiles.Index.DashboardText")}
    >
      <PreviewModal
        show={showPreview}
        setShow={setShowPreview}
        id={previewId}
        setId={setPreviewId}
        title={profileTitle}
        downloadUrl={ProfileConfig.endpoints.download + previewId }
      />

      <Row>
        <Col>
          <div className="mt-4 mb-3 d-flex flex-wrap gap-2">
            <NewButtonWithIcon
              title={t("Backend.Buttons.NewProfile")}
              showPayModal={showPayModal}
              setShowPayModal={setShowPayModal}
              handleClick={() => {
                createNewProfile(setShowPayModal, navigate);
              }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        {profiles.length > 0 &&
          profiles.map((profile, index) => (
            <Profile
              index={index}
              key={profile.id}
              profile={profile}
              profiles={profiles}
              setProfiles={setProfiles}
              setPreviewId={setPreviewId}
              setShowPreview={setShowPreview}
              setProfileTitle={setProfileTitle}
            />
          ))}
      </Row>
    </ContentWrapper>
  );
};
export default Profiles;
