function extractDomainName(url) {
    let parsedUrl;
    try {
        parsedUrl = new URL(url);
    } catch (error) {
       // console.error("Invalid URL provided:", url);
        return; // Exit the function if the URL is invalid
    }
    const parts = parsedUrl.hostname.split('.').reverse();
    if (parts.length >= 3) {
        // Check if the TLD is a known ccSLD, requiring us to take the third part as the domain (e.g., 'co.uk', 'com.au')
        if (parts[1].length === 2 || parts[0].length === 2) {
            return parts[2];
        } else {
            return parts[1]; // For standard domains, take the second part from the end
        }
    } else if (parts.length === 2) {
        return parts[1]; // If there are only two parts, it's likely a domain with a TLD and no subdomain
    }
    return parts[0]; // Fallback to the last part if none of the above conditions match
}

export default extractDomainName;