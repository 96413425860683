import { Routes, Route } from "react-router-dom";
import Signup from "./Signup";
import VerifyEmail from "./VerifyEmail";
import Forgot from "./Forgot";
import RequestValidationLink from "./RequestValidationLink";
import Landingpage from "./Landingpage";

const LoginRouter = ({ setToken }) => {
  return (
    <div className="w-100 min-vh-100 d-flex flex-column align-items-center">
      <Routes>
        <Route path="/signup" element={<Signup />} />
        <Route path="/verifyemail" element={<VerifyEmail />} />
        <Route path="/resetpassword" element={<Forgot />} />
        <Route
          path="/requestemailvalidation"
          element={<RequestValidationLink />}
        />
        <Route path="/*" element={<Landingpage />} />
      </Routes>
    </div>
  );
};

export default LoginRouter;
