import { Link } from "react-router-dom";
import {logo} from "../../../assets/img/logo";

const Brand = () => {
  return (
    <div className="header__brand">
      <div className="brand-wrap">
        {/* <a href="../../" className="brand-img stretched-link">
          <img
            src={Logo}
            alt="Bewerbungshelferlein Logo"
            className="Bewerbungshelferlein logo"
            width="80"
            height="80"
            style={{ height: "50px", width: "50px" }}
          />
  </a>*/}
        <Link to="/" style={{ textDecoration: "none" }}>
          <div className="brand-title">
            <img src={logo} style={{maxHeight: "40px"}}/>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Brand;
