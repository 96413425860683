import Language from "../Entities/Language";

const addLanguage = (formik, setEditLanguage, setShowLanguageForm) =>{
    const arrayLength = formik.values.languages.length;
    if (arrayLength > 0) {
      const lastLanguage = formik.values.languages[arrayLength - 1];
      if (
        //if the last language is empty already don't add a new language use the last one
        typeof lastLanguage !== "undefined" &&
        (typeof lastLanguage.name !== "undefined" ||
          typeof lastLanguage.level !== "undefined")
      ) {
        formik.values.languages.push(Language);
      }
    } else {
      formik.values.languages.push(Language);
    }
    setEditLanguage(null);
    setShowLanguageForm(true);
}

export default addLanguage;