import { useEffect } from "react";
import crudApi from "@serie3/common/api/crudApi";
import { useSearchParams } from "react-router-dom";
import UserConfig from "@serie3/bewerbungshelferleinapi/Modules/User/Resources/Endpoints";
import { useNavigate } from "react-router-dom";

const Callbacks = () => {
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      const socialNetwork = window.location.pathname.split("/").pop();
      let code = searchParams.get("code");
      let connectionEndpoint =
        UserConfig.endpoints.social[socialNetwork].connect;

      let data = {
        code: code,
      };
      let response = await crudApi.createNew(connectionEndpoint, data);
      if (response.ok) {
        navigate("/settings");
      }
    };
    loadData();
  }, []);

  return "done";
};

export default Callbacks;
