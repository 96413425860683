import { Button } from "react-bootstrap";

const AddButton = ({ addFunction }) => {
  return (
    <Button type="button" className="btn btn-primay" onClick={addFunction}>
      <span className="vr"></span>
      <i className="psi-add fs-4" />
    </Button>
  );
};

export default AddButton;
