import CustomToggleBtn from "./CustomToggleBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "react-bootstrap";

const EllipsisVerticalToggle = () => {
  return (
    <Dropdown.Toggle id="dropdown-basic" as={CustomToggleBtn}>
      <FontAwesomeIcon icon={faEllipsisVertical} />
    </Dropdown.Toggle>
  );
};

export default EllipsisVerticalToggle;