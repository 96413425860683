import { Tab } from "react-bootstrap";
import ContentWrapper from "../../Shared/Basics/ContentWrapper";
import { useTranslation } from "react-i18next";
import StyledTabs from "../../Shared/Tabs/StyledTabs";
import ResponsiveTabTitle from "../../Shared/Tabs/ResponsiveTabTitle";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import GmailButton from "./SocialButtons/GmailButton";
import UserProfile from "./UserProfile";
import ProfileForm from "@serie3/bewerbungshelferleinapi/Modules/Profile/Forms/ProfileForm";
import crudApi from "@serie3/common/api/crudApi";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import ProfilePersonalForm from "../Profiles/Subcomponents/Forms/ProfilePersonalForm";
import ProfileAddressForm from "../Profiles/Subcomponents/Forms/ProfileAddressForm";
import { ProfileConfig } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Resources/Endpoints";
import PaymentForm from "../Settings/PaymentForm";
import PaymentDataForm from "@serie3/common/Modules/Payment/Forms/PaymentDataForm";
import Subscriptions from "./Subscriptions";
import loadSubscriptionData from "@serie3/common/Modules/Payment/Domain/loadSubscriptionData";

const Settings = () => {
  const { t } = useTranslation();
  const [systemProfile, setSystemProfile] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const editProfileForm = ProfileForm.createProfileForm();
  const formik = useFormik(editProfileForm);

  const editPaymentDataForm = PaymentDataForm.createPaymentDataForm();
  const paymentFormik = useFormik(editPaymentDataForm);

  useEffect(() => {
    const loadData = async () => {
      const response = await crudApi.justGet(
        ProfileConfig.endpoints.systemProfile
      );
      if (response.ok) {
        setSystemProfile(response.data);
        formik.setValues(ProfileForm.setEditData(response.data));
      }

      loadSubscriptionData(setSubscriptions);
    };

    loadData();
  }, []);

  return (
    <ContentWrapper
      title={t("Backend.Navigation.Settings")}
      welcomeText={t("Backend.Settings.Subtitle")}
      titleText={t("Backend.Settings.SettingsText")}
    >
      <div className="flex-fill">
        <StyledTabs defaultKey="profile">
          <Tab
            eventKey="profile"
            title={
              <ResponsiveTabTitle
                icon={faDatabase}
                fullTitle={t("Backend.Settings.Tabs.Profile.Title")}
              />
            }
            className="nav nav-tabs"
          >
            <ProfilePersonalForm formik={formik} showLanguages={false}/>
            <ProfileAddressForm formik={formik} profile={systemProfile}/>
          </Tab>
          <Tab
            eventKey="account"
            title={
              <ResponsiveTabTitle
                icon={faDatabase}
                fullTitle={t("Backend.Settings.Tabs.Account.Title")}
              />
            }
            className="nav nav-tabs"
          >
            <UserProfile />
          </Tab>
          <Tab
            eventKey="basics"
            title={
              <ResponsiveTabTitle
                icon={faDatabase}
                fullTitle={t("Backend.Settings.Tabs.Connections.Title")}
              />
            }
            className="nav nav-tabs"
          >
            <GmailButton />
          </Tab>
          <Tab
            eventKey="paymentData"
            title={
              <ResponsiveTabTitle
                icon={faDatabase}
                fullTitle={t("Backend.Settings.Tabs.Payments.PaymentData.Title")}
              />
            }
            className="nav nav-tabs"
          >
            <PaymentForm formik={paymentFormik} />
          </Tab>
          <Tab
            eventKey="payments"
            title={
              <ResponsiveTabTitle
                icon={faDatabase}
                fullTitle={t("Backend.Settings.Tabs.Payments.Payments.Title")}
              />
            }
            className="nav nav-tabs"
          >
            <Subscriptions subscriptions={subscriptions} />
          </Tab>
        </StyledTabs>
      </div>
    </ContentWrapper>
  );
};

export default Settings;
