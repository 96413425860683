import ContentWrapper from "../Shared/Basics/ContentWrapper";
import Header from "../Shared/Basics/Header";
import NotLoggedInHeader from "./NotLoggedIn/NotLoggedInHeader";
import { useTranslation } from "react-i18next";
import LoginRouter from "./Login/LoginRouter";

const Notloggedin = ({ setToken, token }) => {
  const { t } = useTranslation();

  return (
    <>
      <Header>
        <NotLoggedInHeader token={token} setToken={setToken} />
      </Header>
      <ContentWrapper>
        <LoginRouter setToken={setToken} />
      </ContentWrapper>
    </>
  );
};

export default Notloggedin;
