function daysSincePastDate(pastDateString) {
    // Parse the past date string and create a Date object
    const pastDate = new Date(pastDateString);
    
    // Get today's date with the time set to 00:00:00 for consistency
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    // Calculate the difference in time between the two dates
    const timeDifference = today.getTime() - pastDate.getTime();
    
    // Convert the time difference from milliseconds to days
    const daysDifference = timeDifference / (1000 * 3600 * 24);
    
    // Return the number of days
    const number =  Math.floor(daysDifference);
    if ( isNaN(number) || number <=0 ){
      return 0;
    }
    return number;
  }
  

  export default daysSincePastDate;