import TextField from "../TextField";
import { Col, Row } from "react-bootstrap";
import KeyValueForm from "@serie3/common/Form/shared/KeyValueForm";
import KeyValue from "@serie3/common/Entity/shared/KeyValue";
import { useFormik } from "formik";
import SaveButton from "../../Buttons/SaveButton";
import NewModal from "../../Modals/NewModal";


const KeyValueFormView = ({
  showEdit,
  setShowEdit,
  editKeyValueData,
  dataList,
  setDataList,
  onShow,
  newEndpoint,
  editEndpoint,
  nameLabel = "Name",
  valueLabel= "Value"
}) => {
  const editKeyValueForm = KeyValueForm.createKeyValueForm(
    dataList,
    setDataList,
    null,
    setShowEdit,
    newEndpoint,
    editEndpoint
  );
  const formik = useFormik(editKeyValueForm);

  const Save = () => {
    return (
      <SaveButton
        submitFunction={(e) => {
          formik.handleSubmit(e);
          e.preventDefault();
        }}
      />
    );
  };

  return (
    <>
      <NewModal
        show={showEdit}
        setShow={setShowEdit}
        ExtraButton={Save}
        onShow={() => {
          editKeyValueData
            ? formik.setValues(KeyValueForm.setEditData(editKeyValueData))
            : formik.setValues(KeyValue);
          onShow(formik);
        }}
        onExit={() => {
          formik.resetForm({ values: { KeyValue } });
        }}
      >
        <Row>
          <Col>
            <TextField
              formik={formik}
              label={nameLabel}
              valueName={"key_name"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
          <Col>
            <TextField
              formik={formik}
              label={valueLabel}
              valueName={"value"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
        </Row>
      </NewModal>
    </>
  );
};

export default KeyValueFormView;
