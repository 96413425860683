import ContentWrapper from "../../Shared/Basics/ContentWrapper";
import { useTranslation } from "react-i18next";
import Card from "../../Shared/Card";
import MailNav from "./Mail/MailNav";
import Mailitem from "./Mail/Mailitem";
const Jobmails = () => {
  const { t } = useTranslation();

  return (
    <ContentWrapper
      title={"Applications"}
      welcomeText={t("Backend.Settings.Subtitle")}
      titleText={t("Backend.Settings.SettingsText")}
    >
      <Card>
        <div className="d-md-flex gap-4">
          <MailNav />
          <div className="flex-fill min-w-0">
            <div className="d-md-flex align-items-center mb-5">
              <h1 className="page-title">Inbox</h1>
              <div className="form-group ms-auto">
                <button className="btn btn-icon btn-success">
                  <i className="pli-repeat-2 fs-5"></i>
                </button>
              </div>
            </div>
            <div className="list-group list-group-borderless gap-1 mb-3">
              <Mailitem />
              <Mailitem />
              <Mailitem />
              <Mailitem />
            </div>
          </div>
        </div>
      </Card>
    </ContentWrapper>
  );
};

export default Jobmails;
