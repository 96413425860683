const MailNav = () => {
  return (
    <div className="w-md-160px w-xl-200px flex-shrink-0 mb-3">
      <div className="d-grid">
        <button type="button" className="btn btn-lg btn-danger hstack gap-3">
          <i className="psi-mail-send fs-3"></i>
          <span className="vr"></span>
          Apply
        </button>
      </div>
      <h5 className="px-3 mt-5 mb-3">Folders</h5>
      <div className="list-group list-group-borderless gap-1">
        <a href="#" className="list-group-item list-group-item-action active">
          <i className="pli-mail-unread fs-5 me-2"></i>
          <h6 className="d-inline">Inbox ( 73 )</h6>
        </a>
        <a href="#" className="list-group-item list-group-item-action">
          <i className="pli-male fs-5 me-2"></i> Address book
        </a>
      </div>
    </div>
  );
};

export default MailNav;
