import { useEffect, useState } from "react";
import crudApi from "@serie3/common/api/crudApi";
import { ProfileConfig } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Resources/Endpoints";
import { useTranslation } from "react-i18next";

const ProfilePicture = ({ profileId, pictureSize = "128px", rerender = null}) => {
  const [proifilePicture, setProfilePicture] = useState('');
  const {t} = useTranslation();

  useEffect(() => {
    const fetchPicture = async () => {
      let previewUrl = ProfileConfig.endpoints.profilePicture + profileId;
      const response = await crudApi.getBinary(previewUrl);
      if (response.status === 200) {
        let file = {
          header: response.headers,
          data: response.data,
        };
        const url = window.URL.createObjectURL(response.data);
        setProfilePicture(url);
      } else {
        setProfilePicture(null);
      }
    };

    fetchPicture();
  }, [profileId, rerender]);

  const PictureDummy = () => {
    return (
      <div
        style={{
          width: pictureSize,
          height: pictureSize,
          borderRadius: "50%",
          backgroundColor: "lightgrey",
          justifyContent: "center",
          alignItems: "center",
          verticalAlign: "middle",
          display: "inline-flex",
          textAlign: "center"
        }}
      >
        <span
          style={{ color: "grey", fontWeight: "bolder", fontSize: "small" }}
        >
          {t('Backend.Profiles.Edit.Picture.NoPicture')}
        </span>
      </div>
    );
  };

  return proifilePicture ? (
    <img
      src={proifilePicture}
      alt="user-avatar"
      className="img-xl rounded-circle"
      style={{ width: pictureSize, height: pictureSize }}
    />
  ) : (
    <PictureDummy />
  );
};

export default ProfilePicture;
