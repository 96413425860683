import listHandler from "@serie3/common/Domain/listHandler";
import { ApplicationConfig } from "../../Profile/Resources/Endpoints";
import crudApi from "@serie3/common/api/crudApi";

const deleteAction = (applicationId, index, setApplications, applications) => {
  const deleteUrl = ApplicationConfig.endpoints.delete;
  listHandler.deleteFromList(
    index,
    crudApi.del(deleteUrl, applicationId),
    setApplications,
    applications
  );
};

const editAction = (application, setEditApplication, setShowForm) => {
  setEditApplication(application);
  setShowForm(true);
};


export {editAction, deleteAction}