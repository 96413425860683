import profilepic from "../../../../assets/img/profile-photos/1.png";
import { useTranslation } from "react-i18next";

const ProfileNav = () => {
  const { t } = useTranslation();
  return (
    <div className="mainnav__profile mt-3 d-flex3">
      <div className="mt-2 d-mn-max"></div>

      <div className="mininav-toggle text-center py-2 collapsed">
        <img
          className="mainnav__avatar img-md rounded-circle border"
          src={profilepic}
          alt="Profile pic"
        />
      </div>
      <div className="mininav-content collapse d-mn-max">
        <div className="d-grid">
          <span className="d-flex justify-content-center align-items-center">
            <h6 className="mb-0">
              {t("Backend.Profiles.Navigation.WelcomeText")}
            </h6>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProfileNav;
