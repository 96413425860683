import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const LoadingModal = ({ showSpinner, setShowSpinner, text="" }) => {
  const { t } = useTranslation();
  const defaultText = text || text === "" ? t("Backend.Modal.Loading") : text;

  return (
    <Modal show={showSpinner} setShow={setShowSpinner}>
      <Modal.Body
        style={{ textAlign: "center", backgroundColor: "transparent" }}
      >
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <div> {defaultText}</div>
      </Modal.Body>
    </Modal>
  );
};

export default LoadingModal;
