import { useEffect, useState } from "react";
import DeleteAccount from "./DeleteAccount";
import crudApi from "@serie3/common/api/crudApi";
import UserConfig from "@serie3/bewerbungshelferleinapi/Modules/User/Resources/Endpoints";
import NewModal from "../../Shared/Modals/NewModal";

const UserProfile = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [error, setError] = useState(false);
  const [deleteDate, setDeleteDate] = useState();
  const [showCancel, setShowCancel] = useState(false);

  useEffect(() => {
    const makeCalls = async () => {
      const pathname = window.location.pathname;
      if (pathname.includes("confirmdelete")) {
        const searchParamsString = window.location.search;
        const response = await crudApi.justGet(
          UserConfig.endpoints.confirmDeleteAccount + searchParamsString
        );

        if (response.status === 200) {
          if (typeof response.data.date === "undefined") {
            setError(true);
          }
          setDeleteDate(response.data.date);
          setShowConfirm(true);
        } else {
          setError(true);
          setShowConfirm(true);
        }
      }

      if (pathname.includes("confirmcanceldelete")) {
        const searchParamsString = window.location.search;
        const response = await crudApi.justGet(
          UserConfig.endpoints.confirmCancelDelete + searchParamsString
        );
        if (response.status === 200) {
          setShowCancel(true);
        } else {
          setError(true);
          setShowCancel(true);
        }
      }
    };
    makeCalls();
  }, []);

  const ConfirmDelete = () => {
    return (
      <NewModal show={showConfirm} setShow={setShowConfirm}>
        {error || typeof deleteDate === "undefined"
          ? "There was an error confirming the request. Plz contact support"
          : "Your account will be deleted on " +
            deleteDate.split(" ")[0] +
            ". You cancel that any time!"}
      </NewModal>
    );
  };

  const ConfirmCancel = () => {
    return (
      <NewModal show={showCancel} setShow={setShowCancel}>
        {error
          ? "There was an error confirming the request. Plz contact support"
          : "Your account will no longer be deleted, all your subscriptions will continue"}
      </NewModal>
    );
  };

  return (
    <>
      <ConfirmCancel />
      <ConfirmDelete />
      <DeleteAccount />
    </>
  );
};

export default UserProfile;
