const AddSkill = (formik, Skill, setEditSkill, setshowSkillsForm) => {
  const lastSkill = formik.values.skills[formik.values.skills.length - 1];
  if (
    (typeof lastSkill !== "undefined" &&
      lastSkill.name &&
      lastSkill.category) ||
    formik.values.skills.length == 0
  ) {
    formik.values.skills.push(Skill);
  }

  setEditSkill(undefined);
  setshowSkillsForm(true);
};

export default AddSkill;
