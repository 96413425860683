import Timeline from "../../../../Shared/Timeline/Timeline";
import EducationTimelineItem from "./Education/EducationTimelineItem";
import FormSaveHeader from "./FormSaveHeader";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import EducationFormView from "./Education/EducationFormView";
import AddButton from "../../../../Shared/Buttons/AddButton";
import { ProfileConfig } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Resources/Endpoints";
import listHandler from "@serie3/common/Domain/listHandler";
import crudApi from "@serie3/common/api/crudApi";

const ProfileEducationForm = ({ profile }) => {
  const [educations, setEducations] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [editEducation, setEditEducation] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    setEducations(profile.education);
  }, [profile]);

  const AddBtn = () => {
    return (
      <AddButton
        addFunction={() => {
          setEditEducation(null);
          setShowEdit(true);
        }}
      />
    );
  };

  const editAction = (education) => {
    setEditEducation(education);
    setShowEdit(true);
  };

  const deleteAction = (index, educationId) => {
    const deleteUrl = ProfileConfig.endpoints.education.delete;
    listHandler.deleteFromList(
      index,
      crudApi.del(deleteUrl, educationId),
      setEducations,
      educations
    );
  };

  return (
    <>
      <EducationFormView
        showEdit={showEdit}
        setShowEdit={setShowEdit}
        profileId={profile.id}
        dataList={educations}
        setDataList={setEducations}
        editEducation={editEducation}
      />
      <FormSaveHeader
        title={t("Backend.Profiles.Edit.Tabs.Titles.EducationTab")}
        ExtraButton={AddBtn}
      />
      <Row className="mb-3">
        <Col>
          <Timeline>
            {educations
              ? educations.map((education, index) => {
                  const labelText = education.start + " - " + education.ende;
                  return (
                    <EducationTimelineItem
                      key={education.id}
                      education={education}
                      editAction={() => {
                        editAction(education);
                      }}
                      deleteAction={() => {
                        deleteAction(index, education.id);
                      }}
                    />
                  );
                })
              : null}
          </Timeline>
        </Col>
      </Row>
    </>
  );
};

export default ProfileEducationForm;
