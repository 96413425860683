import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ResponsiveTabTitle = ({ ignoreWith = false, icon, fullTitle }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {windowWidth > 767 || ignoreWith === true ? (
        fullTitle
      ) : icon ? (
        <FontAwesomeIcon icon={icon} size="xl"/>
      ) : null}
    </>
  );
};

export default ResponsiveTabTitle;
