import { Button, Col, Form, Row } from "react-bootstrap";
import crudApi from "@serie3/common/api/crudApi";
import NewModal from "../../Shared/Modals/NewModal";
import { useEffect, useRef, useState } from "react";
import ReferencedInputAndSubmit from "../../Shared/Form/ReferencedInputAndSubmit";
import UserConfig from "@serie3/bewerbungshelferleinapi/Modules/User/Resources/Endpoints";
import { useTranslation } from "react-i18next";

const DeleteAccount = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteString, setDeleteString] = useState();
  const [checkEmail, setCheckEmail] = useState(false);
  const [deleteDate, setDeleteDate] = useState();
  const [showCancel, setShowCancel] = useState(false);

  const confirmfieldRef = useRef(null);
  const { t } = useTranslation();

  const generateRandomString = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  useEffect(() => {
    const checkDeletion = async () => {
      const response = await crudApi.justGet(
        UserConfig.endpoints.checkDeletion
      );
      if (response.status === 200) {
        const date = response.data.date.split(" ")[0];
        setDeleteDate(date);
      }
    };

    setDeleteString(generateRandomString(10));
    checkDeletion();
  }, []);

  const sendDeleteRequest = async () => {
    if (confirmfieldRef.current.value === deleteString) {
      const response = await crudApi.justGet(
        UserConfig.endpoints.deleteAccount
      );
      if (response.status === 200) {
        setCheckEmail(true);
      }
    }
  };

  const DeletePasswordModal = () => {
    return (
      <NewModal
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        title={"Confirm deletion of your account"}
      >
        {checkEmail ? (
          <Row>
            <Col>{t("ProfilePage.CheckMailString")}</Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col style={{ backgroundColor: "lightGrey", padding: "10px" }}>
                {t("ProfilePage.ConfirmDeleteString")} <b>{deleteString}</b>
              </Col>
            </Row>
            <br />
            <Row>
              <Form>
                <ReferencedInputAndSubmit
                  namedReference={confirmfieldRef}
                  label={"Delete Account"}
                  onClick={sendDeleteRequest}
                />
              </Form>
            </Row>
            <Row>
              <Col>{t("ProfilePage.DeleteinDaysMessage")}</Col>
            </Row>
          </>
        )}
      </NewModal>
    );
  };

  const getDeletePassword = () => {
    setShowDeleteModal(true);
  };

  const cancelDelete = async () => {
    const response = await crudApi.justGet(UserConfig.endpoints.cancelDelete);
    if (response.status === 200) {
      setShowCancel(true);
      setCheckEmail(true);
    }
  };

  const CancelDeleteModal = () => {
    return (
      <NewModal show={showCancel} setShow={setShowCancel}>
        {checkEmail ? (
          <Row>
            <Col>{t("ProfilePage.CheckMailString")}</Col>
          </Row>
        ) : (
          t("ErrorMessages.ContactSupport")
        )}
      </NewModal>
    );
  };

  const CancelDeletionButton = () => {
    return (
      <>
        <Row>
          <Col>
            <Button onClick={cancelDelete}>
              {" "}
              {t("ProfilePage.CancelDelete")}{" "}
            </Button>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col>
            {t("ProfilePage.DeleteDateMessage")} <b>{deleteDate}</b>{" "}
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <CancelDeleteModal />
      <DeletePasswordModal />
      <Row>
        <Col>
          {deleteDate ? (
            <CancelDeletionButton />
          ) : (
            <Button onClick={getDeletePassword}> {t("ProfilePage.DeleteAccountButton")} </Button>
          )}
        </Col>
      </Row>
    </>
  );
};

export default DeleteAccount;
