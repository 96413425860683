import TextField from "../../../../Shared/Form/TextField";
import DateField from "../../../../Shared/Form/DateField";
import { Col, Row, Table, Button } from "react-bootstrap";
import Checkbox from "../../../../Shared/Form/Checkbox";
import LanguageForm from "../../../../Shared/Form/FullForms/LanguageForm";
import { useState } from "react";
import CompleteDropdown from "../../../../Shared/Dropdown/CompleteDropdown";
import { useTranslation } from "react-i18next";
import FormSaveHeader from "./FormSaveHeader";
import addLanguage from "@serie3/bewerbungshelferleinapi/Modules/Profile/Domain/AddLanguage";
import TagLikeHandler from "@serie3/common/Domain/TagLikeHandler";
import setLanguageLevelText from "@serie3/bewerbungshelferleinapi/Modules/Profile/Domain/setLanguageLevelText";

const ProfilePersonalForm = ({
  formik,
  languages,
  setLanguages,
  showLanguages = true,
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [editLanguage, setEditLanguage] = useState();
  const { t } = useTranslation();

  const AddLanguageButton = () => {
    return (
      <Button
        type="button"
        className="btn btn-primay"
        onClick={() => {
          addLanguage(formik, setEditLanguage, setShowEdit);
        }}
      >
        <span className="vr"></span>
        <i className="psi-add fs-4" />
      </Button>
    );
  };

  return (
    <>
      <LanguageForm
        showEdit={showEdit}
        setShowEdit={setShowEdit}
        dataList={formik.values.languages}
        formik={formik}
        setDataList={setLanguages}
        editLanguage={editLanguage}
      />
      <FormSaveHeader
        formik={formik}
        title={t("Backend.Profiles.Edit.Tabs.Titles.NameBirth")}
      />

      <Row className="g-3 mb-5">
        <Col md={4}>
          <TextField
            label={t("Backend.Profiles.Edit.Tabs.Titles.Personal.FirstName")}
            formik={formik}
            valueName={"vorname"}
          />
        </Col>
        <Col md={4}>
          <TextField
            label={t("Backend.Profiles.Edit.Tabs.Titles.Personal.LastName")}
            formik={formik}
            valueName={"name"}
          />
        </Col>
        <Col md={4}>
          <DateField
            label={t("Backend.Profiles.Edit.Tabs.Titles.Personal.Birthdate")}
            formik={formik}
            valueName={"birthdate"}
          />
        </Col>
      </Row>
      <Row className="g-3 mb-5 justify-content-end">
        <Col md={4}>
          <Checkbox
            formik={formik}
            label={t(
              "Backend.Profiles.Edit.Tabs.Titles.Personal.PublishBirthdate"
            )}
            valueName={"birthdate_public"}
          />
        </Col>
      </Row>

      {showLanguages && (
        <>
          <FormSaveHeader
            formik={formik}
            title={t("Backend.Profiles.Edit.Tabs.Titles.Languages")}
            ExtraButton={AddLanguageButton}
          />
          <Row className="g-3">
            <Col md={12}>
              {setLanguages ? (
                <Table
                  className="table table-striped pb-5"
                  style={{ overflowX: "auto" }}
                >
                  <tbody>
                    {languages
                      ? languages.map((language, index) => {
                          return (
                            <tr key={index}>
                              <td>{language.name}</td>
                              <td>{setLanguageLevelText(language.level)}</td>
                              <td>
                                <CompleteDropdown
                                  deleteAction={() => {
                                    TagLikeHandler.deleteElement(
                                      language,
                                      "languages",
                                      setLanguages,
                                      formik
                                    );
                                  }}
                                  editAction={() => {
                                    setEditLanguage(language);
                                    setShowEdit(true);
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </Table>
              ) : null}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ProfilePersonalForm;
