import { ApplicationConfig } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Resources/Endpoints";
import crudApi from "@serie3/common/api/crudApi";

const LoadApplications = async (setAllApplications, applicationEndpoint = ApplicationConfig.endpoints.getAll) => {
  const allApplicationsResponse = await crudApi.getAll(applicationEndpoint);
  const dataField = ApplicationConfig.datafield;
  let allApplications;
  if (!allApplicationsResponse.data) {
    console.error("No data returned from the API");
    return; // Exit the function or handle this case appropriately
  }
  try {
    allApplications = allApplicationsResponse.data[dataField];
    setAllApplications(allApplications);
    return allApplications;
  } catch (error) {
    console.error("Failed to fetch all applications:", error);
    return; // Exit the function or handle the error appropriately
  }
};

export default LoadApplications;
