const PaymentData = {
  first_name: "",
  last_name: "",
  company_name: "",
  street: "",
  house_number: "",
  postal_code: "",
  city: "",
  country: "",
  vat_number: "",
  delivery_street: "",
  delivery_house_number: "",
  delivery_postal_code: "",
  delivery_city: "",
  delivery_country: "",
};

export default PaymentData;
