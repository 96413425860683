import Application from "../Entities/Application";
import * as Yup from "yup";
import crudApi from "@serie3/common/api/crudApi";
import { ApplicationConfig } from "../Resources/Endpoints";
import listHandler from "@serie3/common/Domain/listHandler";

const createApplicationForm = (dataList, setDataFunction, setShowEdit) => {
  const validationSchema = Yup.object({
    job_title: Yup.string().required("Required"),
    page: Yup.string().required("Required"),
  });

  const onSubmit = async (values, actions) => {
    //needed because here is nothing to modify with the profile. Also once the application is done with the profile it should not be ediable anymore
    if (values.hasOwnProperty("profile")) {
      delete values.profile;
    }
    const payload = JSON.stringify(values);
    let response;
    if (values.id > 0) {
      response = await crudApi.edit(ApplicationConfig.endpoints.edit, payload);
      listHandler.updateList(
        response,
        JSON.stringify(response.data),
        dataList,
        setDataFunction
      );
    } else {
      response = await crudApi.createNew(
        ApplicationConfig.endpoints.new,
        payload
      );

      listHandler.newToList(response, dataList, setDataFunction, null, true);
    }
    if (response.status === 200) {
      setShowEdit(false);
    }
  };

  return {
    initialValues: Application,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      onSubmit(values, actions);
    },
  };
};

const setEditData = (editData) => {
  let Application = {
    id: editData.id,
    hireing_company: editData.hireing_company,
    job_title: editData.job_title,
    link: editData.link,
    page: editData.page,
    location: editData.location,
    status: editData.satus,
  };

  return Application;
};

export default { createApplicationForm, setEditData };
