import { Row, Col } from "react-bootstrap";
import TextField from "../../../Shared/Form/TextField";
import { useTranslation } from "react-i18next";

const ApplicationFormComponent = ({ formik }) => {
  const { t } = useTranslation();
  return (
    <>
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={t("Backend.Application.Form.Jobtitle")}
            valueName={"job_title"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
        <Col>
          <TextField
            formik={formik}
            label={t("Backend.Application.Form.Hireingcompany")}
            valueName={"hireing_company"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={t("Backend.Application.Form.Page")}
            valueName={"page"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={t("Backend.Application.Form.Joblink")}
            valueName={"link"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={t("Backend.Application.Form.Worklocation")}
            valueName={"location"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
    </>
  );
};

export default ApplicationFormComponent;
