import NewModal from "./NewModal";
import { useTranslation } from "react-i18next";

const PayModal = ({ show, setShow }) => {
    const {t} = useTranslation();
  return (
    <NewModal show={show} setShow={setShow}>
      {t('Backend.Pay.Header')}
    </NewModal>
  );
};

export default PayModal;
