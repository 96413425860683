import NewModal from "../../../../../Shared/Modals/NewModal";
import { Col, Row } from "react-bootstrap";
import TextField from "../../../../../Shared/Form/TextField";
import TextArea from "../../../../../Shared/Form/TextArea";
import { useFormik } from "formik";
import DateField from "../../../../../Shared/Form/DateField";
import SaveButton from "../../../../../Shared/Buttons/SaveButton";
import WorkexperienceForm from "@serie3/bewerbungshelferleinapi/Modules/Profile/Forms/WorkexperienceForm";
import Workexperience from "@serie3/bewerbungshelferleinapi/Modules/Profile/Entities/Workexperience";
import { useTranslation } from "react-i18next";
import ReferencedInputAndSubmit from "../../../../../Shared/Form/ReferencedInputAndSubmit";
import { useRef, useEffect } from "react";
import JobTaskForm from "@serie3/bewerbungshelferleinapi/Modules/Profile/Forms/JobTaskForm";
import { ProfileConfig } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Resources/Endpoints";
import crudApi from "@serie3/common/api/crudApi";
import listHandler from "@serie3/common/Domain/listHandler";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const WorkexperienceFormView = ({
  showEdit,
  setShowEdit,
  editData,
  setEditData,
  profileId,
  dataList,
  setDataList,
  jobTasks = [],
  setJobTasks,
}) => {
  const editWorkexperienceForm = WorkexperienceForm.createWorkexperienceForm(
    dataList,
    setDataList,
    setEditData,
    setJobTasks
  );
  const formik = useFormik(editWorkexperienceForm);
  const { t } = useTranslation();
  const jobRef = useRef(null);

  useEffect(() => {
    if (editData) {
      setJobTasks(editData.job_tasks);
    } else {
      setJobTasks([]);
    }
  }, [editData]);

  const Save = () => {
    return (
      <>
        <SaveButton
          submitFunction={(e) => {
            formik.handleSubmit(e);
            e.preventDefault();
          }}
        />
        <SaveButton
          buttonText={"Save and close"}
          submitFunction={(e) => {
            formik.handleSubmit(e);
            e.preventDefault();
            setShowEdit(false);
          }}
        />
      </>
    );
  };

  const jobTaksForm = JobTaskForm.createJobTaskForm(
    jobTasks,
    setJobTasks,
    null
  );
  const jobTaksFormik = useFormik(jobTaksForm);

  const addNewJobTask = (e) => {
    jobTaksFormik.setFieldValue("description", jobRef.current.value);
    jobTaksFormik.setFieldValue("workexperience", { id: editData.id });
    jobRef.current.value = "";

    jobTaksFormik.handleSubmit();
    e.preventDefault();
  };

  const deleteJobTask = (index, jobTaskId) => {
    const deleteUrl = ProfileConfig.endpoints.workexperience.deleteJobTask;
    listHandler.deleteFromList(
      index,
      crudApi.del(deleteUrl, jobTaskId),
      setJobTasks,
      jobTasks
    );
  };

  return (
    <>
      <NewModal
        show={showEdit}
        setShow={setShowEdit}
        title={t("Backend.Profiles.Edit.Tabs.WorkExperience.Form.FormTitle")}
        ExtraButton={Save}
        onShow={() => {
          editData
            ? formik.setValues(WorkexperienceForm.setEditData(editData))
            : formik.setValues(Workexperience);

          formik.setFieldValue("profile.id", profileId);
        }}
        onExited={() => {
          formik.resetForm({ values: { Workexperience } });
        }}
      >
        <Row>
          <Col>
            <TextField
              formik={formik}
              label={t(
                "Backend.Profiles.Edit.Tabs.WorkExperience.Form.PositionName"
              )}
              valueName={"position_name"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <DateField
              formik={formik}
              label={t("Backend.Profiles.Edit.Tabs.WorkExperience.Form.Start")}
              valueName={"start"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
          <Col>
            <DateField
              formik={formik}
              label={t("Backend.Profiles.Edit.Tabs.WorkExperience.Form.End")}
              valueName={"ende"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              formik={formik}
              label={t(
                "Backend.Profiles.Edit.Tabs.WorkExperience.Form.PositionType"
              )}
              valueName={"position_type"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              formik={formik}
              label={t(
                "Backend.Profiles.Edit.Tabs.WorkExperience.Form.Company"
              )}
              valueName={"company"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
          <Col>
            <TextField
              formik={formik}
              label={t(
                "Backend.Profiles.Edit.Tabs.WorkExperience.Form.Headcount"
              )}
              valueName={"headcount"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              formik={formik}
              label={t(
                "Backend.Profiles.Edit.Tabs.WorkExperience.Form.Business"
              )}
              valueName={"business"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-2">
            <TextArea
              formik={formik}
              label={t(
                "Backend.Profiles.Edit.Tabs.WorkExperience.Form.Description"
              )}
              valueName={"description"}
            />
          </Col>
        </Row>
        {editData && (
          <>
            <Row>
              <Col>
                <label className="form-label">
                  {t(
                    "Backend.Profiles.Edit.Tabs.WorkExperience.Form.Technologies"
                  )}
                </label>
                <ReferencedInputAndSubmit
                  onClick={addNewJobTask}
                  namedReference={jobRef}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ul className="list-group">
                  {jobTasks &&
                    jobTasks.map((task, index) => (
                      <li
                        key={index}
                        className="list-group-item list-group-item-action"
                      >
                        {task.description}
                        <span
                          style={{ float: "right", display: "inline-block" }}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            style={{
                              float: "right",
                              paddingLeft: "15px",
                              paddingRight: "10px",
                              color: "red",
                            }}
                            onClick={() => {
                              deleteJobTask(index, task.id);
                            }}
                          />
                        </span>
                      </li>
                    ))}
                </ul>
              </Col>
            </Row>
          </>
        )}
      </NewModal>
    </>
  );
};

export default WorkexperienceFormView;
