import crudApi from "@serie3/common/api/crudApi";

const downloadHandler = async (downloadUrl, name = 'data', data = null, setShowSpinner) => {
  if (typeof name === "undefined") {
    name = "Data";
  }

  let response;
  if (data === null) {
    response = await crudApi.getBinary(downloadUrl);
  } else {
    response = await crudApi.getWithData(downloadUrl, data);
  }
  if (typeof setShowSpinner !== "undefined") {
    setShowSpinner(false);
  }
  const url = window.URL.createObjectURL(response.data);
  const link = window.document.createElement("a");
  link.href = url;
  link.setAttribute("download", name);
  window.document.body.appendChild(link); // Required for this to work in FireFox
  link.click();
};

export default downloadHandler;
