import { useState, useRef, useEffect } from "react";
import Skillswrapper from "./Skills/Skillswrapper";
import handleSkillSplitup from "@serie3/bewerbungshelferleinapi/Modules/Profile/Domain/handleSkillSplitup";
import { Row, Table, Button, Col } from "react-bootstrap";
import FormSaveHeader from "./FormSaveHeader";
import { useTranslation } from "react-i18next";
import AddSkill from "@serie3/bewerbungshelferleinapi/Modules/Profile/Domain/AddSkill";
import SkillsForm from "./Skills/SkillsForm";
import Tag from "../../../../Shared/Buttons/Tag";
import TagLikeHandler from "@serie3/common/Domain/TagLikeHandler";
import ReferencedInputAndSubmit from "../../../../Shared/Form/ReferencedInputAndSubmit";

const ProfileSkillsForm = ({
  formik,
  originalSkillList,
  setOriginalSkillList,
  qualifications,
  setQualifications,
}) => {
  const [showSkillsForm, setshowSkillsForm] = useState(false);
  const [skillList, setSkillList] = useState([]);
  const [editSkill, setEditSkill] = useState(null);
  const softSkillInputRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const modifiedSkillArray = handleSkillSplitup(originalSkillList);
    setSkillList(modifiedSkillArray);
    setQualifications(formik.values.qualifications);
  }, [originalSkillList]);

  const AddSkillButton = (Skill) => {
    return (
      <div className="card-tools ml-auto">
        <Button
          className="success"
          onClick={() => {
            AddSkill(formik, Skill, setEditSkill, setshowSkillsForm);
          }}
        >
          <span className="vr"></span>
          <i className="psi-add fs-4" />
        </Button>
      </div>
    );
  };

  return (
    <>
      <SkillsForm
        showEdit={showSkillsForm}
        setShowEdit={setshowSkillsForm}
        dataList={originalSkillList}
        setDataList={setOriginalSkillList}
        formik={formik}
        editSkill={editSkill}
      />
      <FormSaveHeader
        formik={formik}
        title={t("Backend.Profiles.Edit.Tabs.Titles.HardSkills")}
        ExtraButton={AddSkillButton}
      />
      <Row className="g-3 mb-5">
        <Col>
          <Table
            borderless
            striped
            className="pb-5"
            style={{ overflowX: "auto" }}
          >
            <Skillswrapper
              skillList={skillList}
              setEditFunction={setEditSkill}
              setShowEdit={setshowSkillsForm}
              setDataList={setOriginalSkillList}
              dataList={originalSkillList}
              formik={formik}
            />
          </Table>
        </Col>
      </Row>
      <FormSaveHeader
        formik={formik}
        title={t("Backend.Profiles.Edit.Tabs.Titles.SoftSkills")}
        noButton={true}
      />
      <Row>
        <Col>
          <ReferencedInputAndSubmit
            namedReference={softSkillInputRef}
            onClick={() => {
              TagLikeHandler.addElement(
                formik,
                softSkillInputRef,
                "qualifications"
              );
            }}
          />
        </Col>
      </Row>
      <Row className="g-3 mb-5">
        <Col>
          {qualifications
            ? qualifications.map((softSkill, index) => {
                return (
                  <Tag
                    key={index}
                    item={softSkill}
                    content={softSkill.name}
                    deleteFunction={() => {
                      TagLikeHandler.deleteElement(
                        softSkill,
                        "qualifications",
                        setQualifications,
                        formik
                      );
                    }}
                  />
                );
              })
            : null}
        </Col>
      </Row>
    </>
  );
};

export default ProfileSkillsForm;
