import crudApi from "@serie3/common/api/crudApi";
import listHandler from "@serie3/common/Domain/listHandler";
import CompleteDropdown from "../Dropdown/CompleteDropdown";

const KeyValueTableRow = ({
  index,
  keyValueObject,
  setShowEdit,
  setEditFunction,
  setKeyValueData,
  keyValueDataList,
  showErrorFunction = null,
  deleteUrl,
}) => {
  const deleteData = async () => {
    listHandler.deleteFromList(
      index,
      crudApi.del(deleteUrl, keyValueObject.id),
      setKeyValueData,
      keyValueDataList,
      showErrorFunction
    );
  };

  return (
    <tr>
      <td>{keyValueObject.key_name}</td>
      <td>{keyValueObject.value}</td>
      <td style={{ verticalAlign: "middle" }}>
        <CompleteDropdown
          editAction={() => {
            setEditFunction(keyValueObject);
            setShowEdit(true);
          }}
          deleteAction={deleteData}
        />
      </td>
    </tr>
  );
};

export default KeyValueTableRow;
