import TextField from "../../../../Shared/Form/TextField";
import { Col, Row, Table, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormSaveHeader from "./FormSaveHeader";
import Checkbox from "../../../../Shared/Form/Checkbox";
import KeyValueTableRow from "../../../../Shared/Table/KeyValueTableRow";
import { ProfileConfig } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Resources/Endpoints";
import KeyValueFormView from "../../../../Shared/Form/FullForms/KeyValueFormView";
import { useState } from "react";

const ProfileAddressForm = ({
  formik,
  profile,
  socialMediaList,
  setSocialMediaList,
}) => {
  const { t } = useTranslation();
  const [showSocialMediaForm, setShowSocialMediaForm] = useState(false);
  const [editSocialMedia, setEditSocialMedia] = useState();

  const SocialMediaButton = () => {
    return (
      <div className="card-tools ml-auto">
        <Button
          className="success"
          onClick={() => {
            setEditSocialMedia(null);
            setShowSocialMediaForm(true);
          }}
        >
          <span className="vr"></span>
          <i className="psi-add fs-4" />
        </Button>
      </div>
    );
  };

  const onShow = (formik) => {
    formik.setFieldValue("profile.id", profile.id);
  };

  return (
    <>
      <KeyValueFormView
        showEdit={showSocialMediaForm}
        setShowEdit={setShowSocialMediaForm}
        editKeyValueData={editSocialMedia}
        dataList={socialMediaList}
        setDataList={setSocialMediaList}
        onShow={onShow}
        newEndpoint={ProfileConfig.endpoints.socialMedia.new}
        editEndpoint={ProfileConfig.endpoints.socialMedia.edit}
        nameLabel={t(
          "Backend.Profiles.Edit.Tabs.Titles.Address.SocicalForm.NameLabel"
        )}
        valueLabel={t(
          "Backend.Profiles.Edit.Tabs.Titles.Address.SocicalForm.ValueLabel"
        )}
      />
      <FormSaveHeader
        formik={formik}
        title={t("Backend.Profiles.Edit.Tabs.Titles.AddressFormTitle")}
      />
      <Row className="g-3 mb-5">
        <Col md={6}>
          <TextField
            label={t("Backend.Profiles.Edit.Tabs.Titles.Address.Street")}
            formik={formik}
            valueName={"street"}
          />
        </Col>
        <Col md={6}>
          <TextField
            label={t("Backend.Profiles.Edit.Tabs.Titles.Address.Number")}
            formik={formik}
            valueName={"housenumber"}
          />
        </Col>
        <Col md={6}>
          <TextField
            label={t("Backend.Profiles.Edit.Tabs.Titles.Address.Postcode")}
            formik={formik}
            valueName={"postcode"}
          />
        </Col>
        <Col md={6}>
          <TextField
            label={t("Backend.Profiles.Edit.Tabs.Titles.Address.City")}
            formik={formik}
            valueName={"city"}
          />
        </Col>
      </Row>

      <FormSaveHeader
        formik={formik}
        title={t("Backend.Profiles.Edit.Tabs.Titles.Contacts")}
        noButton={true}
      />
      <Row className="g-3 mb-5">
        <Col md={6}>
          <TextField
            label={t("Backend.Profiles.Edit.Tabs.Titles.Address.Mobile")}
            formik={formik}
            valueName={"mobile"}
          />
        </Col>
        <Col md={6}>
          <TextField
            label={t("Backend.Profiles.Edit.Tabs.Titles.Address.Telefon")}
            formik={formik}
            valueName={"telefon"}
          />
        </Col>
        <Col md={6}>
          <Checkbox
            formik={formik}
            label={t("Backend.Profiles.Edit.Tabs.Titles.Address.PublishMobile")}
            valueName={"mobile_public"}
          />
        </Col>
        <Col md={6}>
          <Checkbox
            formik={formik}
            label={t("Backend.Profiles.Edit.Tabs.Titles.Address.PublishPhone")}
            valueName={"telefon_public"}
          />
        </Col>
        <Col md={6}>
          <TextField
            label={t("Backend.Profiles.Edit.Tabs.Titles.Address.Email")}
            formik={formik}
            valueName={"email"}
          />
        </Col>
        <Col md={6}>
          <TextField
            label={t("Backend.Profiles.Edit.Tabs.Titles.Address.Website")}
            formik={formik}
            valueName={"website"}
          />
        </Col>
        <Col md={6}>
          <Checkbox
            formik={formik}
            label={t("Backend.Profiles.Edit.Tabs.Titles.Address.PublishEmail")}
            valueName={"email_public"}
          />
        </Col>
      </Row>

      {typeof setSocialMediaList !== "undefined" && (
        <>
          <FormSaveHeader
            formik={formik}
            title={t("Backend.Profiles.Edit.Tabs.Titles.SocialMedia")}
            ExtraButton={SocialMediaButton}
          />
          <Row className="g-3 mb-5">
            <Table
              className="table table-striped projects pb-5"
              style={{ overflowX: "auto" }}
            >
              <tbody>
                {socialMediaList
                  ? socialMediaList.map((socialMedia, index) => {
                      return (
                        <KeyValueTableRow
                          index={index}
                          key={index}
                          keyValueObject={socialMedia}
                          setShowEdit={setShowSocialMediaForm}
                          setEditFunction={setEditSocialMedia}
                          setKeyValueData={setSocialMediaList}
                          keyValueDataList={socialMediaList}
                          deleteUrl={ProfileConfig.endpoints.socialMedia.delete}
                        />
                      );
                    })
                  : null}
              </tbody>
            </Table>
          </Row>
        </>
      )}
    </>
  );
};

export default ProfileAddressForm;
