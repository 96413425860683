const firebaseConfig = {
    apiKey: "AIzaSyAIf31IhH_C2hm-EHPdD_YF9JCnjvGQLiM",
    authDomain: "bewerbungshelferlein-stage.firebaseapp.com",
    projectId: "bewerbungshelferlein-stage",
    storageBucket: "bewerbungshelferlein-stage.appspot.com",
    messagingSenderId: "499549726263",
    appId: "1:499549726263:web:abad856c28278df7a681b2",
    measurementId: "G-42SWGS020Z"
  };

  export default firebaseConfig;