import daysSincePastDate from "@serie3/bewerbungshelferleinapi/Domain/CalculateDays";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ApplicationStatusDropdown from "../user/Applications/ApplicationStatusDropdown";

const ApplicatonRow = ({
  application,
  index,
  deleteAction,
  editAction,
  updateView,
  setUpdateView,
}) => {
  const { t } = useTranslation();
  const days = daysSincePastDate(application.date);

  return (
    <tr>
      <td>
        <button
          type="button"
          className="btn btn-icon btn-danger btn-xs rounded-circle"
        >
          <b>{days}</b>
        </button>
      </td>
      <td>{application.job_title}</td>
      <td>{application.hireing_company}</td>
      <td>
        <a className="btn-link" target="_blank" href={application.link}>
          {application.page}
        </a>
      </td>
      <td className="text-center">
        <ApplicationStatusDropdown application={application} updateView={updateView} setUpdateView={setUpdateView}/>
      </td>
      <td className="text-center text-nowrap">
        <Button
          className="btn btn-icon btn-sm btn-success btn-hover"
          onClick={() => {
            editAction(application);
          }}
        >
          <i className="pli-pen-5 fs-5"></i>
        </Button>
        <Button
          className="btn btn-icon btn-sm btn-danger btn-hover"
          onClick={() => {
            deleteAction(application.id, index);
          }}
        >
          <i className="pli-trash fs-5"></i>
        </Button>
      </td>
    </tr>
  );
};

export default ApplicatonRow;
