import ApplicationStatusDropdown from "./ApplicationStatusDropdown";
import { Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import daysSincePastDate from "@serie3/bewerbungshelferleinapi/Domain/CalculateDays";

const ApplicationItem = ({
  application,
  index,
  deleteAction = null,
  editAction = null,
  updateView = null,
  setUpdateView = null,
  composeMode = false,
  setStep1 = null,
}) => {
  const days = daysSincePastDate(application.date);
  const ageTip = (
    <Tooltip id="draft">
      <small>{days} Days old</small>
    </Tooltip>
  );

  return (
    <div className="list-group-item list-group-item-action d-flex align-items-center">
      <div className="d-flex flex-column flex-md-row flex-shrink-0 gap-3 align-items-center">
        <a href="#" className="text-decoration-none text-light">
          <span className="me-2">
            <i className="psi-star fs-5"></i>
          </span>
        </a>
      </div>

      <div className="flex-fill min-w-0 ms-3">
        <div className="d-flex flex-wrap flex-xl-nowrap align-items-xl-center">
          <div className="flex-fill w-100 min-w-0 mt-2 mt-xl-0 mx-xl-3 order-xl-2">
            <a href="#" className="text-dark fw-bold m-0 text-decoration-none">
              {application.job_title}
            </a>
          </div>

          <div className="flex-fill w-100 min-w-0 mt-2 mt-xl-0 mx-xl-3 order-xl-2">
            <a
              href="#"
              className="d-block text-dark fw-bold m-0 text-decoration-none text-truncate"
            >
              {application.hireing_company}
            </a>
          </div>
          <div className="flex-fill w-50 min-w-0 mt-2 mt-xl-0 mx-xl-3 order-xl-2">
            <OverlayTrigger placement="bottom" overlay={ageTip}>
              <small
                className={`text-body-secondary ${
                  days > 5 ? (days > 10 ? "text-danger" : "text-warning") : null
                }`}
              >
                {application.date}
              </small>
            </OverlayTrigger>
          </div>
          {composeMode ? (
            <div className="d-flex flex-fill w-100 min-w-0 mt-2 mt-xl-0 mx-xl-3 order-xl-2 justify-content-end">
              <Button onClick={() => setStep1(application)} className="btn btn-success">Use</Button>
            </div>
          ) : (
            <>
              <div className="flex-fill w-100 min-w-0 mt-2 mt-xl-0 mx-xl-3 order-xl-2">
                <ApplicationStatusDropdown
                  application={application}
                  updateView={updateView}
                  setUpdateView={setUpdateView}
                  small={true}
                />
              </div>
              <div className="flex-fill w-50 min-w-0 mt-2 mt-xl-0 mx-xl-3 order-xl-2">
                <Button
                  className="btn btn-icon btn-sm btn-success btn-hover"
                  onClick={() => {
                    editAction();
                  }}
                >
                  <i className="pli-pen-5 fs-5"></i>
                </Button>
                <Button
                  className="btn btn-icon btn-sm btn-danger btn-hover ms-3"
                  onClick={() => {
                    deleteAction();
                  }}
                >
                  <i className="pli-trash fs-5"></i>
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplicationItem;
