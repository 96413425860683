import { initializeApp } from "firebase/app";
import firebaseConfig from "@serie3/bewerbungshelferleinapi/Config/Firebase/firebase";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useRef, useState } from "react";
import Card from "../../Shared/Card";
import { useTranslation, Trans } from "react-i18next";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";

const Forgot = () => {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const emailFieldRef = useRef(null);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const { t } = useTranslation();

  const resetPassword = () => {
    const email = emailFieldRef.current.value;
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setShowSuccess(true);
      })
      .catch((error) => {
        setShowError(true);
      });
  };
  return (
    <Card>
      <div className="text-center">
        <h1 className="h3">
          <Trans>Login.Forgot.Title</Trans>
        </h1>
        <p>
          <Trans>Login.Forgot.Subtitle</Trans>
        </p>
      </div>

      <div className="mt-4">
        <Form.Control
          placeholder={t("Login.Forgot.Placeholder")}
          type={"text"}
          ref={emailFieldRef}
        />

        <div className="d-grid mt-5">
          <button
            className="btn btn-warning btn-lg"
            type="submit"
            onClick={() => {
              setShowError(false);
              resetPassword();
            }}
          >
            {t("Login.Forgot.Button")}
          </button>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-4">
        <Link to={"/login"} className="btn-link text-decoration-none">
          {t("Login.Login")}
        </Link>
        <Link to={"/signup"} className="btn-link text-decoration-none">
          {t("Login.New Account")}
        </Link>
      </div>
      <div className="align-items-center justify-content-between text-center border-top pt-3 mt-3">
        {showError ? (
          <span style={{ color: "red" }}> {t("Login.Messages.Error")} </span>
        ) : (
          ""
        )}
        {showSuccess ? (
          <span style={{ color: "green" }}>{t("Login.Messages.Success")} </span>
        ) : (
          ""
        )}
      </div>
    </Card>
  );
};
export default Forgot;
