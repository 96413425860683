import ContentWrapper from "./Basics/ContentWrapper";

const Notfound = () => {
  return (
    <ContentWrapper
      title={"404 - Page not found soorrrryyy!"}
      overlapping={false}
      rounded={false}
    >
      Please contact support if you think we messed up
    </ContentWrapper>
  );
};

export default Notfound;
