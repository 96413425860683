import i18n from "../../../../web/src/i18n";

const setSkilltext = (percentage) => {
  switch (percentage) {
    case 100:
      return i18n.t("Backend.Profiles.Edit.Tabs.Skills.Form.Level.AbsoluteExpert");

    case 75:
      return i18n.t("Backend.Profiles.Edit.Tabs.Skills.Form.Level.Expert");

    case 50:
      return i18n.t("Backend.Profiles.Edit.Tabs.Skills.Form.Level.Intermediate");

    case 25:
      return i18n.t("Backend.Profiles.Edit.Tabs.Skills.Form.Level.Beginner");
      
    default:
      return "-";
  }
};

export default setSkilltext;
