import ContentWrapper from "../../Shared/Basics/ContentWrapper";
import { useTranslation } from "react-i18next";
import Card from "../../Shared/Card";
import ApplicationHeader from "./Applications/ApplicationHeader";
import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import LoadApplications from "@serie3/bewerbungshelferleinapi/Modules/Applications/Domain/LoadApplications";
import ApplicationItem from "./Applications/ApplicationItem";
import ComposeApplicationModal from "../Applications/Subcomponents/ComposeApplicationModal";
import {
  deleteAction,
  editAction,
} from "@serie3/bewerbungshelferleinapi/Modules/Applications/Domain/ApplicationCrudActions";
import ApplicationModal from "../Applications/Subcomponents/ApplicationModal";
import PreviewModal from "../../Shared/Modals/PreviewModal";
import { ProfileConfig } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Resources/Endpoints";
import GmailButton from "./SocialButtons/GmailButton";
import getIsPayedUser from "@serie3/bewerbungshelferleinapi/Modules/User/Domain/getIsPayedUser";
import UserConfig from "@serie3/bewerbungshelferleinapi/Modules/User/Resources/Endpoints";
import crudApi from "@serie3/common/api/crudApi";

const Applications = () => {
  const { t } = useTranslation();
  const [applications, setApplications] = useState([]);
  const [updateView, setUpdateView] = useState(false);
  const [showComposeApplication, setShowComposeApplication] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [editApplication, setEditApplication] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [previewId, setPreviewId] = useState(-1);
  const [profileTitle, setProfileTitle] = useState("");
  const [isConnected, setIsConnected] = useState(false);


  useEffect(() => {
    LoadApplications(setApplications);
    const isConnected = async () => {
      let response = await crudApi.justGet(
        UserConfig.endpoints.social.gmail.isConneted
      );
      if (response.ok && response.data.connected) {
        setIsConnected(true);
      }
    };

    isConnected();
  }, []);

  const StartApplicationButton = () => {

    return isConnected ? (
      <button
        type="button"
        className="btn btn-info hstack gap-3"
        onClick={() => setShowComposeApplication(true)}
      >
        <i className="psi-file-edit fs-4"></i>
        <span className="vr"></span>
        Start application
      </button>
    ): <GmailButton />;
  };

  return (
    <>
      <ApplicationModal
        show={showForm}
        setShow={setShowForm}
        applications={applications}
        setApplications={setApplications}
        application={editApplication}
      />
      <ComposeApplicationModal
        show={showComposeApplication}
        setShow={setShowComposeApplication}
        applications={applications}
        setApplications={setApplications}
        setShowPreview={setShowPreview}
        setPreviewId={setPreviewId}
        setPreviewProfileTitle={setProfileTitle}
      />

      <PreviewModal
        show={showPreview}
        setShow={setShowPreview}
        id={previewId}
        setId={setPreviewId}
        title={profileTitle}
        downloadUrl={ProfileConfig.endpoints.download + previewId}
      />
      <ContentWrapper
        title={"Applications"}
        welcomeText={t("Backend.Settings.Subtitle")}
        titleText={t("Backend.Settings.SettingsText")}
      >
        <ApplicationHeader Button={StartApplicationButton} />
        <Row>
          <Col>
            <Card>
              <div className="d-md-flex gap-4">
                <div className="flex-fill min-w-0">
                  <div className="list-group list-group-borderless gap-1 mb-3">
                    {Array.isArray(applications) &&
                      applications.length > 0 &&
                      applications.map((application, index) => {
                        return (
                          <ApplicationItem
                            index={index}
                            application={application}
                            key={application.id}
                            updateView={updateView}
                            setUpdateView={setUpdateView}
                            deleteAction={() => {
                              deleteAction(
                                application.id,
                                index,
                                setApplications,
                                applications
                              );
                            }}
                            editAction={() => {
                              editAction(
                                application,
                                setEditApplication,
                                setShowForm
                              );
                            }}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </ContentWrapper>
    </>
  );
};

export default Applications;
