import { Row, Col, Card } from "react-bootstrap";
import {
  HowItWorks1,
  HowItWorks2,
  HowItWorks3,
  ArrowLeft,
  ArrowRight,
} from "../../../../assets/img/landingpage/howItWorks";
import { Trans } from "react-i18next";

const HowItWorksComponent = () => {
  return (
    <>
      <Row style={{ marginBottom: "4rem", marginTop: "7.23rem" }}>
        <Col className="d-flex flex-grow-1 text-center justify-content-center howitworks">
         <Trans i18nKey="Landingpage.HowItWorks" />
        </Col>
      </Row>
      <Row
        className="justify-content-center"
        style={{ marginBottom: "8.01rem" }}
      >
        <Col xs={12} md={3} className="d-flex">
          <Card className="flex-grow-1 text-center howitworkscard">
            <Card.Body>
              <div className="d-flex justify-content-center pictogram">
                <HowItWorks1 />
              </div>
              <Card.Title>
                <Trans i18nKey="Landingpage.Create" />
              </Card.Title>
              <Card.Text>
                <Trans i18nKey="Landingpage.CreateText" />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col
          md={1}
          className="d-none d-md-flex justify-content-center align-items-center"
        >
          <ArrowRight />
        </Col>
        <Col xs={12} md={3} className="d-flex">
          <Card className="flex-grow-1 text-center howitworkscard">
            <Card.Body>
              <div
                className="d-flex justify-content-center pictogram"
                style={{ paddingLeft: "30px" }}
              >
                <HowItWorks2 />
              </div>
              <Card.Title>
                <Trans i18nKey="Landingpage.Track" />
              </Card.Title>
              <Card.Text>
                <Trans i18nKey="Landingpage.TrackText">
                  With our <a href="https://chrome.google.com/webstore/detail/fmepoclkebcklfggldfepfpgmcnompgp" target="_blank">chrome plugin</a> you
                  can quick and easy add jobs you find online to your todo list
                </Trans>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col
          md={1}
          className="d-none d-md-flex justify-content-center align-items-center"
        >
          <ArrowLeft />
        </Col>
        <Col xs={12} md={3} className="d-flex">
          <Card
            className="flex-grow-1 text-center howitworkscard"
            style={{ backgroundColor: "#F7F9FF" }}
          >
            <Card.Body>
              <div className="d-flex justify-content-center pictogram">
                <HowItWorks3 />
              </div>
              <Card.Title>
                <Trans i18nKey="Landingpage.Download" />
              </Card.Title>
              <Card.Text>
                <Trans i18nKey="Landingpage.DownloadText" />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default HowItWorksComponent;
