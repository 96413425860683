import { Link } from "react-router-dom";

const NavLink = ({ title, target, icon = null, children = null }) => {
  return (
    <li className="nav-item">
      <Link to={target} className="mininav-toggle nav-link">
        <i className={icon}></i>
        <span className="nav-label mininav-content ms-1">{title}</span>
      </Link>
    </li>
  );
};

export default NavLink;
