import crudApi from "@serie3/common/api/crudApi";
import { ProfileConfig, ApplicationConfig } from "../Resources/Endpoints";

const createNewProfile = async (setShowPayModal, navigate) => {
    const response = await crudApi.createNew(
      ProfileConfig.endpoints.new,
      {},
      setShowPayModal
    );
    if (response.status === 200) {
      navigate( "/profile/edit/" + response.data.id);
    }
  };

  const createNewApplicaton = async (setShowPayModal, showApplicatonModal) => {
    showApplicatonModal(true);
    /*
    const response = await crudApi.createNew(
      ApplicationConfig.endpoints.new,
      {},
      setShowPayModal
    );
    if (response.status === 200) {
      showApplicatonModal(true)
    }
    */
  };

  export {createNewProfile, createNewApplicaton}