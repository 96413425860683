
import Tabs from "react-bootstrap/Tabs";
import { useState } from "react";

const StyledTabs = ({ children, defaultKey="" }) => {
  const [key, setKey] = useState(defaultKey);
  return (
    <div className="tab-base">
      <Tabs
        defaultActiveKey="profile"
        id="uncontrolled-tab-example"
        className="tab-base"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        {children}
      </Tabs>
    </div>
  );
};

export default StyledTabs;
