import TextField from "../../../../Shared/Form/TextField";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TextArea from "../../../../Shared/Form/TextArea";
import Tag from "../../../../Shared/Buttons/Tag";
import ReferencedInputAndSubmit from "../../../../Shared/Form/ReferencedInputAndSubmit";
import TagLikeHandler from "@serie3/common/Domain/TagLikeHandler";
import { useRef } from "react";
import FormSaveHeader from "./FormSaveHeader";
import Checkbox from "../../../../Shared/Form/Checkbox";
const ProfileBasicsForm = ({ formik, searchingFor, setSearchingFor }) => {
  const { t } = useTranslation();
  const searchingInputRef = useRef(null);

  return (
    <>
      <Row className="g-3 mb-5">
        <Col md={12}>
          <FormSaveHeader
            formik={formik}
            title={t("Backend.Profiles.Edit.Tabs.Titles.Basics")}
          />
        </Col>
        <Col md={12}>
          <TextField
            label={t("Backend.Profiles.Edit.Tabs.Titles.Title")}
            formik={formik}
            valueName={"profiletitle"}
          />
        </Col>
        <Col md={12}>
          <TextArea
            label={t("Backend.Profiles.Edit.Tabs.Titles.Mantra")}
            formik={formik}
            valueName={"mantra"}
          />
        </Col>
      </Row>
      <Row className="g-3 mt-3">
        <Col md={12}>
          <FormSaveHeader
            formik={formik}
            title={t("Backend.Profiles.Edit.Tabs.Titles.RemoteOnly")}
            noButton={true}
          />
        </Col>
      </Row>
      <Row className="g-3">
        <Col>
          <Checkbox
            formik={formik}
            label={"Remote only"}
            valueName={"remote_only"}
          />
        </Col>
      </Row>
      <Row className="g-3 mt-3">
        <Col md={12}>
          <h5 className="mb-3 d-flex justify-content-between align-items-center">
            {t("Backend.Profiles.Edit.Tabs.Titles.LookingFor")}
          </h5>
        </Col>
        <Row>
          <Col>
            <ReferencedInputAndSubmit
              namedReference={searchingInputRef}
              onClick={() => {
                TagLikeHandler.addElement(
                  formik,
                  searchingInputRef,
                  "_searching"
                );
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {searchingFor.map((searching, index) => {
              return (
                <Tag
                  key={index}
                  item={searching}
                  content={searching.name}
                  deleteFunction={() => {
                    TagLikeHandler.deleteElement(
                      searching,
                      "_searching",
                      setSearchingFor,
                      formik
                    );
                  }}
                />
              );
            })}
          </Col>
        </Row>
      </Row>
    </>
  );
};
export default ProfileBasicsForm;
