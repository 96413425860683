import addComma from "@serie3/common/Modules/Payment/Domain/payment";
import { Table, Button } from "react-bootstrap";
import downloadHandler from "@serie3/common/Shared/downloadHandler";
import { PaymentConfig } from "@serie3/common/Modules/Payment/Resources/Endpoints";
import PDFModal from "../../Shared/Modals/PDFModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Subscriptions = ({ subscriptions = [] }) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const { t } = useTranslation();

  return subscriptions.length > 0 ? (
    <>
      <PDFModal setShowSpinner={setShowSpinner} showSpinner={showSpinner} />
      <Table striped>
        <thead>
          <tr>
            <th style={{ textAlign: "center" }}>
              {t("Backend.Settings.Tabs.Payments.Payments.Date")}
            </th>
            <th style={{ textAlign: "center" }}>
              {t("Backend.Settings.Tabs.Payments.Payments.ProductName")}
            </th>
            <th style={{ textAlign: "center" }}>
              {t("Backend.Settings.Tabs.Payments.Payments.Quantity")}
            </th>
            <th style={{ textAlign: "center" }}>
              {t("Backend.Settings.Tabs.Payments.Payments.Price")}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {subscriptions
            ? subscriptions.map((subcription, index) => {
                return (
                  <tr>
                    <td style={{ textAlign: "center" }}>{subcription.date}</td>
                    <td style={{ textAlign: "center" }}>
                      {subcription.product_name}
                    </td>
                    <td style={{ textAlign: "center" }}>1</td>
                    <td style={{ textAlign: "center" }}>
                      {addComma(subcription.price)} €
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {subcription.payload_id && (
                        <Button
                          onClick={() => {
                            setShowSpinner(true);
                            downloadHandler(
                              PaymentConfig.endpoints.subscriptions
                                .downloadInvoice +
                                "/" +
                                subcription.id,
                              "bewerbungshelferlein-rechnung.pdf",
                              null,
                              setShowSpinner
                            );
                          }}
                        >
                          <th style={{ textAlign: "center" }}>
                            {t(
                              "Backend.Settings.Tabs.Payments.Payments.DownloadButton"
                            )}
                          </th>
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </Table>
    </>
  ) : (
    "No Payments made"
  );
};

export default Subscriptions;
