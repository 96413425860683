import { Dropdown } from "react-bootstrap";


const StatusDropdown = ({ text, action}) => {
  return (
    <>
      <Dropdown.Item
        onClick={() => {
          action();
        }}
      >
        <small>{text}</small>
      </Dropdown.Item>
    </>
  );
};

export default StatusDropdown;
