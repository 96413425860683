import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const PDFModal = ({ showSpinner, setShowSpinner }) => {
  const { t } = useTranslation();

  return (
    <Modal show={showSpinner} setShow={setShowSpinner}>
      <Modal.Body
        style={{ textAlign: "center", backgroundColor: "transparent" }}
      >
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <div> {t("Backend.Profiles.Modal.PDF.Spinner")}</div>
      </Modal.Body>
    </Modal>
  );
};

export default PDFModal;
