import TextField from "../../Shared/Form/TextField";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormSaveHeader from "../Profiles/Subcomponents/Forms/FormSaveHeader";
import { useEffect, useState } from "react";
import loadPaymentData from "@serie3/common/Modules/Payment/Domain/loadPaymentData";

const PaymentForm = ({ formik }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    loadPaymentData(setIsLoaded, formik);
  }, []);

  return isLoaded ? (
    <>
      <FormSaveHeader formik={formik} title={t("Backend.Payment.Edit.Title")} />
      <Row className="g-3 mb-3">
        <Col>
          <TextField
            label={t("Backend.Payment.Edit.CompanyName")}
            formik={formik}
            valueName={"company_name"}
          />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col sm={6}>
          <TextField
            label={t("Backend.Payment.Edit.FirstName")}
            formik={formik}
            valueName={"first_name"}
          />
        </Col>
        <Col sm={6}>
          <TextField
            label={t("Backend.Payment.Edit.LastName")}
            formik={formik}
            valueName={"last_name"}
          />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col sm={6}>
          <TextField
            label={t("Backend.Payment.Edit.Street")}
            formik={formik}
            valueName={"street"}
          />
        </Col>
        <Col sm={6}>
          <TextField
            label={t("Backend.Payment.Edit.HouseNumber")}
            formik={formik}
            valueName={"house_number"}
          />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col sm={6}>
          <TextField
            label={t("Backend.Payment.Edit.PostalCode")}
            formik={formik}
            valueName={"postal_code"}
          />
        </Col>
        <Col sm={6}>
          <TextField
            label={t("Backend.Payment.Edit.City")}
            formik={formik}
            valueName={"city"}
          />
        </Col>
      </Row>
      <Row className="g-3 mb-5">
        <Col sm={6}>
          <TextField
            label={t("Backend.Payment.Edit.Country")}
            formik={formik}
            valueName={"country"}
          />
        </Col>
        <Col sm={6}>
          <TextField
            label={t("Backend.Payment.Edit.VatNumber")}
            formik={formik}
            valueName={"vat_number"}
          />
        </Col>
      </Row>
      <h5 className="g-3 mb-3">{t("Backend.Payment.Edit.DeliveryAddress")}</h5>
      <Row className="g-3 mb-3">
        <Col sm={6}>
          <TextField
            label={t("Backend.Payment.Edit.DeliveryStreet")}
            formik={formik}
            valueName={"delivery_street"}
          />
        </Col>

        <Col sm={6}>
          <TextField
            label={t("Backend.Payment.Edit.DeliveryHouseNumber")}
            formik={formik}
            valueName={"delivery_house_number"}
          />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col sm={6}>
          <TextField
            label={t("Backend.Payment.Edit.DeliveryPostalCode")}
            formik={formik}
            valueName={"delivery_postal_code"}
          />
        </Col>
        <Col sm={6}>
          <TextField
            label={t("Backend.Payment.Edit.DeliveryCity")}
            formik={formik}
            valueName={"delivery_city"}
          />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col sm={6}>
          <TextField
            label={t("Backend.Payment.Edit.DeliveryCountry")}
            formik={formik}
            valueName={"delivery_country"}
          />
        </Col>
      </Row>
    </>
  ) : (
    ""
  );
};

export default PaymentForm;
