import TimelineItem from "../../../../../Shared/Timeline/TimelineItem";
import { useTranslation } from "react-i18next";
import CompleteDropdown from "../../../../../Shared/Dropdown/CompleteDropdown";

const EducationTimelineItem = ({ education, editAction, deleteAction }) => {
  const { t } = useTranslation();
  return (
    <TimelineItem start={education.start} end={education.ende}>
      <h5 className="card-header" style={{display: "flex",  justifyContent: "space-between", alignItems: "center"}}>
        <span>
          {t("Backend.Profiles.Edit.Education.Degree")}
          <i>{education.name}</i>
          <br />
          <small>@{education.subject_area}</small>
        </span>
        <span style={{display: "inline-flex", alignItems: "center"}}>
        <CompleteDropdown deleteAction={deleteAction} editAction={editAction} customStyle={{marginLeft: "5px" }}/>
        </span>
      </h5>
      <div className="card-body">
        <p className="card-text">{education.description}</p>
      </div>
    </TimelineItem>
  );
};

export default EducationTimelineItem;
