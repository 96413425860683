import SaveButton from "../../../../Shared/Buttons/SaveButton";

const FormSaveHeader = ({ formik = null, title, ExtraButton = null, noButton = false }) => {
  return (
    <h5 className="mb-3 d-flex justify-content-between align-items-center">
      {title}
      {ExtraButton ? <ExtraButton /> : (!noButton && formik) && <SaveButton formik={formik} />}
    </h5>
  );
};

export default FormSaveHeader;
