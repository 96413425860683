import Timer from "./Timer";
import i18n from "../../i18n";
import useToken from "../../security/useToken";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import SettingsForm from "@serie3/bewerbungshelferleinapi/Modules/User/Forms/SettingsForm";
import SettingsContext from "@serie3/common/auth/SettingsContext";
import { useContext } from "react";

const ProfileDropdown = () => {
  const { token } = useToken();
  const navigate = useNavigate();
  const { settings } = useContext(SettingsContext);

  const editSettingsForm = SettingsForm.createSettingsForm(i18n);
  const formik = useFormik(editSettingsForm);

  const changeLanguage = (lng = "en") => {
    formik.setFieldValue("id", settings.id);
    formik.setFieldValue("language", lng);
    formik.submitForm();
  };

  const { t } = useTranslation();

  return (
    <>
      {token && (
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            <button
              className="header__btn btn btn-icon btn-sm show"
              type="button"
              data-bs-toggle="dropdown"
              aria-label="User dropdown"
              aria-expanded="true"
            >
              <i className="psi-male"></i>
            </button>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item style={{ backgroundColor: "white" }}>
              <div
                className="w-md-450px show"
                data-popper-placement="bottom-end"
              >
                <div className="row">
                  <div className="col-md-7">
                    <div className="list-group list-group-borderless mb-3">
                      <Timer />
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="list-group list-group-borderless h-100 py-3">
                      <a
                        className="list-group-item list-group-item-action"
                        onClick={() => {
                          navigate("/settings");
                        }}
                      >
                        {t("Backend.User.Dropdown.Profilelink")}
                      </a>
                      <a
                        href="#"
                        className="list-group-item list-group-item-action mt-auto"
                        onClick={() => changeLanguage("en")}
                      >
                        <span className="fi fi-us fis" /> &nbsp;
                        {t("Translation.English")}
                      </a>
                      <a
                        href="#"
                        className="list-group-item list-group-item-action"
                        onClick={() => changeLanguage("de")}
                      >
                        <span className="fi fi-de fis" />
                        &nbsp; {t("Translation.German")}
                      </a>

                      <a
                        onClick={() => {
                          navigate("/settings");
                        }}
                        href="#"
                        className="list-group-item list-group-item-action mt-3 mt-auto"
                      >
                        <i className="pli-gear fs-5 me-2"></i>
                        {t("Backend.Navigation.Settings")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
};

export default ProfileDropdown;
