import crudApi from "@serie3/common/api/crudApi";
import UserConfig from "../Resources/Endpoints";

const getIsPayed = async (setIsPayedUser) => {
  let response = await crudApi.justGet(
    UserConfig.endpoints.settings.isPayedUser
  );
  if (response.status === 200) {
    setIsPayedUser(true);
  } else {
    setIsPayedUser(false);
  }
};

export default getIsPayed;
