const PaymentConfig = {
  endpoints: {
    paymentData: {
      get: "payments/paymentdata",
      new: "payments/newPaymentData",
      edit: "payments/editPaymentData",
    },
    subscriptions: {
      list: "payments/subscriptions",
      downloadInvoice: "payments/downloadinvoice",
    },
  },
  dataFields: {
    subscriptions: "settings",
  },
};

export { PaymentConfig };
