import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import "../../../../styles/footer.scss";
import { Col } from "react-bootstrap";
import { logoColor } from "../../../../assets/img/logoColor";

const Footer = ({ token = null }) => {
  const NonLoggedinFooter = () => {
    return (
      <footer className="row row-cols-1 row-cols-sm-2 row-cols-md-5 py-5 border-top outsideFooter">
        <Col></Col>
        <Col className="ms-3 mb-3">
          <p>
            <img src={logoColor} />
          </p>

          <p>Copyright ©2024 Steal this if you like!</p>
        </Col>

        <Col className="col mb-3">
          <h5>Links</h5>
          <ul className="nav flex-column">
            <li className="nav-item mb-2">
              <Link to={"/blog/datenschutz"}>
                <Trans i18nKey="Footer.Privacylink" />
              </Link>
            </li>
            <li className="nav-item mb-2">
              <Link className="me-3" to={"/blog/impressum"}>
                <Trans i18nKey="Footer.Imprintlink" />
              </Link>
            </li>
            <li className="nav-item mb-2">
              <Link className="me-3" to={"/"}>
                <Trans i18nKey="Footer.Startpage" />
              </Link>
            </li>
          </ul>
        </Col>
      </footer>
    );
  };

  const LoggedinFooter = () => {
    return (
      <footer className="mt-auto footer">
        <div className="content__boxed">
          <div
            className="content__wrap  d-flex flex-column flex-md-row align-items-md-center"
            style={{ paddingTop: "0.4rem", paddingBottom: "0.5rem" }}
          >
            <div className="text-nowrap fs-5">
              <Link className="nav-link" to={"/blog/datenschutz"}>
                <Trans i18nKey="Footer.Privacylink" />
              </Link>
              <Link className="nav-link" to={"/blog/impressum"}>
                <Trans i18nKey="Footer.Imprintlink" />
              </Link>

              <Link className="nav-link" to={"/"}>
                <Trans i18nKey="Footer.Startpage" />
              </Link>
            </div>
          </div>
        </div>
      </footer>
    );
  };

  return token ? <LoggedinFooter /> : <NonLoggedinFooter />;
};

export default Footer;
