import { Routes, Route } from "react-router-dom";
import Profiles from "./Profiles";
import ProfileEdit from "./ProfileEdit";
import Notfound from "../../Shared/Notfound";

const ProfileRouter = () => {

    return (
        <Routes>
            <Route path="/" element={<Profiles />} />
            <Route path="/edit/:id" element={<ProfileEdit />} />
            <Route path="*" element={<Notfound />} />
        </Routes>
    )
}

export default ProfileRouter;