const Table = ({ children, style }) => {
  return (
    <div className="table-responsive mw-100">
      <table className="table table-borderless table-sm align-middle" style={{...style}}>
        {children}
      </table>
    </div>
  );
};

export default Table;
