import { useState } from "react";
import { Modal } from "react-bootstrap";
import Login from "../Login/Login";

const LoginModal = ({ show, setShow, setToken }) => {
  const [showLoginError, setShowLoginError] = useState(false);
  

  return (
    <Modal
      show={show}
      onShow={() => {
        setShowLoginError(false);
      }}
      onHide={() => setShow(false)}
      dialogClassName="modal-80w"
    >
      <Modal.Header closeButton={true} style={{ border: "0px" }} />
      <Modal.Body
        style={{ textAlign: "center", padding: "2.5rem 3.5rem 3.5rem 4.5rem" }}
      >
        <Login setShow={setShow} setToken={setToken} />
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
